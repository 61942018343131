import * as actionTypes from 'actions/debtReportActions';
import moment from 'moment';

const initFilter = {
  customer: '',
  plate: '',
  start_date: moment()
    .subtract(120, 'days')
    .toDate(),
  end_date: moment()
    .add(30, 'days')
    .toDate()
};
const initialState = {
  dashboard: {
    cong_no: [],
    labels: []
  },
  toan_bo_cong_no: 0,
  list_don: [],
  filter: initFilter,
  loading: false,
  customers: []
};

const debtReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.GET_DASHBOARD_REPORT: {
      return {
        ...state,
        dashboard: action.dashboard,
        toan_bo_cong_no: action.toan_bo_cong_no,
        list_don: action.list_don
      };
    }

    case actionTypes.GET_FILTER_DATA: {
      return {
        ...state,
        customers: action.customers
      };
    }

    default: {
      return state;
    }
  }
};

export default debtReportReducer;
