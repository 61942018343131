export const SET_FILE_DATA = 'SET_FILE_DATA';
export const SET_SEARCH_FILE_DATA = 'SET_SEARCH_FILE_DATA';
export const OPEN_ADD_CONTRACT = 'OPEN_ADD_CONTRACT';

export const setFileData = fileData => dispatch =>
  dispatch({
    type: SET_FILE_DATA,
    fileData
  });

export const setSearchFileData = searchFileData => dispatch =>
  dispatch({
    type: SET_SEARCH_FILE_DATA,
    searchFileData
  });

export const openAddContract = open => dispatch =>
  dispatch({
    type: OPEN_ADD_CONTRACT,
    open
  });
