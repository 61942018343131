import * as actionTypes from 'actions/generalReportActions';
import moment from 'moment';

const initFilter = {
  start_date: moment()
    .subtract(10, 'days')
    .toDate(),
  end_date: moment()
    .add(10, 'days')
    .toDate()
};
const initialState = {
  dashboard: {
    doanh_thu: [],
    tong_chi_phi: [],
    tong_lai: [],
    labels: []
  },
  filter: initFilter,
  loading: false,
  Label: []
};

const generalReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.GET_DASHBOARD_REPORT: {
      return {
        ...state,
        dashboard: action.dashboard,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export default generalReportReducer;
