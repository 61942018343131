import { monthlyGeneralReportApi } from 'apis';
const prefix = 'monthlyReport';

export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const GET_DASHBOARD_REPORT = `${prefix}_GET_DASHBOARD_REPORT`;
export const SET_LOADING = `${prefix}_SET_LOADING`;

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    filter
  });
};
export const getDashboardReport = data => async dispatch => {
  dispatch(setLoading(true));
  const res = await monthlyGeneralReportApi(data);
  const dashboard = {
    doanh_thu: [],
    tong_chi_phi: [],
    tong_lai: [],
    labels: []
  };
  res.forEach(data => {
    dashboard.doanh_thu.push(data.doanh_thu);
    dashboard.tong_chi_phi.push(data.tong_chi_phi);
    dashboard.tong_lai.push(data.tong_lai);
    dashboard.labels.push(data.month);
  });
  dispatch({
    type: GET_DASHBOARD_REPORT,
    dashboard
  });
};
