import { getOrdersSSApi } from 'apis';

export const SET_FILTER_DATA = 'SET_FILTER_SS_DATA';
export const SET_ORDERS = 'SET_SS_ORDERS';
export const SET_LOADING = 'SET_SS_LOADING';
export const SET_SELECTED_ORDER = 'SET_SELECTED_SS_ORDER';

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setOrders = listOrder => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getOrders = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const orders = await getOrdersSSApi(status, startDate, endDate);
    dispatch(setOrders(orders));
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedOrder = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ORDER,
    selected
  });
};
