import { expensesListApi, fixedExpensesApi } from 'apis';

const prefix = 'expressManagement'

export const SET_EXPENSES = `${prefix}_SET_EXPENSES`;
export const SET_FIXED_EXPENSES = `${prefix}_SET_FIXED_EXPENSES`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_LOADING_FIXEX_EXPRESS = `${prefix}_SET_LOADING_FIXEX_EXPRESS`;
export const SET_SELECTED_EXPENSES = `${prefix}_SET_SELECTED_EXPENSES`;
export const UPDATE_FORM = `${prefix}_UPDATE_FORM_EXPENSES`;
export const SET_SELECTED_FIXED_EXPENSES = `${prefix}_SET_SELECTED_FIXED_EXPENSES`;
export const UPDATE_FORM_FIXED_EXPRESS = `${prefix}_UPDATE_FORM_FIXED_EXPRESS`;

export const setExpensess = expensess => dispatch => {
  dispatch({
    type: SET_EXPENSES,
    expensess
  });
};

export const setFixedExpensess = fixedExpensess => dispatch => {
  dispatch({
    type: SET_FIXED_EXPENSES,
    fixedExpensess
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setLoadingFixedExpress = loadingFixedExpress => dispatch => {
  dispatch({
    type: SET_LOADING_FIXEX_EXPRESS,
    loadingFixedExpress
  });
};

export const getExpensess = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const expensess = await expensesListApi();
    dispatch(setExpensess(expensess));
  } catch (error) {
    console.log('get expensess error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedExpenses = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_EXPENSES,
    selected
  });
};

export const setSelectedFixedExpenses = selectedFixedExpenses => dispatch => {
  dispatch({
    type: SET_SELECTED_FIXED_EXPENSES,
    selectedFixedExpenses
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const updateFormFixedExpress = formStateFixedExpress => dispatch => {
  dispatch({
    type: UPDATE_FORM_FIXED_EXPRESS,
    formStateFixedExpress
  });
};


export const getFixedExpensess = () => async dispatch => {
  dispatch(setLoadingFixedExpress(true));
  try {
    const fixedExpensess = await fixedExpensesApi();
    dispatch(setFixedExpensess(fixedExpensess));
  } catch (error) {
    console.log('get FixedExpensess error ', error);
    dispatch(setLoadingFixedExpress(false));
  }
};