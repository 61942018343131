import * as actionTypes from 'actions/salaryAction';

const initialState = {
  vehicles: [],
  loading: false,
  selectedVehicle: [],
  formState: { open: false, edit: false, sdt: '' },
  sortType: 'Không lọc',
  filterTime: new Date(),
  filterForm: {
    open: false,
    list: [],
    selected: []
  }
};

const salaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VEHICLE: {
      return {
        ...state,
        vehicles: action.vehicles,
        loading: false,
        selectedVehicle: []
      };
    }

    case actionTypes.SET_SORT_TYPE: {
      return {
        ...state,
        sortType: action.sortType
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }
    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filterTime: action.data
      };
    }
    case actionTypes.SET_FILTER_FORM: {
      return {
        ...state,
        filterForm: {
          ...state.filterForm,
          ...action.data
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default salaryReducer;
