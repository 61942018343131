import { getTripsApi, formTripList, formTripListChiPhi } from 'apis';

const prefix = 'tripManagement';
export const SET_FILTER_DATA_CHI_PHI = `${prefix}_SET_FILTER_DATA_CHI_PHI`;
export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const SET_FILTER_FORM = `${prefix}_SET_FILTER_FORM`;
export const SET_FILTER_FORM_CHI_PHI = `${prefix}_SET_FILTER_FORM_CHI_PHI`;
export const SET_TRIPS = `${prefix}_SET_TRIPS`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_LOADING_BOX = `${prefix}_SET_LOADING_BOX`;
export const SET_SELECTED_TRIP = `${prefix}_SET_SELECTED_TRIP`;
export const REMOVE_SELECTED_TRIP = `${prefix}_REMOVE_SELECTED_TRIP`;
export const SET_TAB_CHOICE = `${prefix}_SET_TAB_CHOICE`;

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setFilterChiPhi = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA_CHI_PHI,
    data
  });
};

export const setFilterForm = data => dispatch => {
  if (data.selected1) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter1',
      JSON.stringify(data.selected1)
    );
  }
  if (data.selected2) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter2',
      JSON.stringify(data.selected2)
    );
  }
  dispatch({
    type: SET_FILTER_FORM,
    data
  });
};

export const setFilterFormChiPhi = data => dispatch => {
  if (data.selected) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter_chi_phi',
      JSON.stringify(data.selected)
    );
  }
  dispatch({
    type: SET_FILTER_FORM_CHI_PHI,
    data
  });
};

export const setTrips = listTrip => dispatch => {
  dispatch({
    type: SET_TRIPS,
    listTrip
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setLoadingBox = loadingBox => dispatch => {
  dispatch({
    type: SET_LOADING_BOX,
    loadingBox
  });
};

export const getTrips = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const trips = await getTripsApi(status, startDate, endDate);
    if (trips.message != undefined) {
      alert(trips.message);
      dispatch(setLoading(false));
    } else {
      dispatch(setTrips(trips));
    }
  } catch (error) {
    console.log('get trip error ', error);
    dispatch(setLoading(false));
  }
};

export const getFilterForm = () => async dispatch => {
  try {
    const res = await formTripList();
    const newList1 = res.tab1;
    const localList1 =
      window.localStorage.getItem('tms_local_list_excel_filter1') || '';
    const localSelectedList1 =
      window.localStorage.getItem('tms_local_selected_list_excel_filter1') ||
      '';

    if (localList1 && JSON.parse(localList1).length !== newList1.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter1',
        JSON.stringify(newList1)
      );
    }

    const newList2 = res.tab2;
    const localList2 =
      window.localStorage.getItem('tms_local_list_excel_filter2') || '';
    const localSelectedList2 =
      window.localStorage.getItem('tms_local_selected_list_excel_filter2') ||
      '';

    if (localList2 && JSON.parse(localList2).length !== newList2.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter2',
        JSON.stringify(newList2)
      );
    }

    dispatch(
      setFilterForm({
        list1: newList1,
        list2: newList2,
        selected1: localSelectedList1 ? JSON.parse(localSelectedList1) : [],
        selected2: localSelectedList2 ? JSON.parse(localSelectedList2) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm ', error);
  }
};

export const getFilterFormChiPhi = () => async dispatch => {
  try {
    const list = await formTripListChiPhi();
    const newList = list.map(item => item.name);
    const localList =
      window.localStorage.getItem('tms_local_list_excel_filter_chi_phi') || '';
    const localSelectedList =
      window.localStorage.getItem(
        'tms_local_selected_list_excel_filter_chi_phi'
      ) || '';

    if (localList && JSON.parse(localList).length !== newList.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter_chi_phi',
        JSON.stringify(newList)
      );
    }

    dispatch(
      setFilterFormChiPhi({
        list: newList,
        selected: localSelectedList ? JSON.parse(localSelectedList) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm', error);
  }
};

export const setSelectedTrip = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_TRIP,
    selected
  });
};

export const removeSelectedTrip = removed => dispatch => {
  dispatch({
    type: REMOVE_SELECTED_TRIP,
    removed
  });
};

export const setTabChoose = tabChoice => dispatch => {
  dispatch({
    type: SET_TAB_CHOICE,
    tabChoice
  });
};
