import { getUserInfo } from 'apis';
import { updateDetailInfo } from 'actions/orderSwitchStationActions';
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_SET_INFO = 'SESSION_SET_INFO';

export const login = data => async dispatch => {
  window.localStorage.setItem('access_token', data.access_token);
  const userInfo = await getUserInfo(data.first_name);
  dispatch(setInfo(userInfo));
  dispatch({
    type: SESSION_LOGIN,
    data
  });
};

export const logout = () => dispatch => {
  window.localStorage.setItem('access_token', '');
  window.localStorage.setItem('matar_username', '');
  dispatch({
    type: SESSION_LOGOUT
  });
};

export const setInfo = data => dispatch => {
  window.localStorage.setItem('matar_username', data.username);
  dispatch(
    updateDetailInfo({
      senderName: data.company_name,
      receiverName: data.company_name
    })
  );
  dispatch({
    type: SESSION_SET_INFO,
    data
  });
};
