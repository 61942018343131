import * as actionTypes from 'actions/WalletManagementActions';

function roundTimeQuarterHour() {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
}

const initialState = {
  drivers: [],
  filter: {
    userId: '',
    role: '',
    fullname: '',
    filterMonth: roundTimeQuarterHour()
  },
  loading: false,
  selectedListTamUng: [],
  selectedListTienChi: [],
  response: {
    so_du_nam: 0,
    so_du_thang: 0,
    tong_da_chi: 0,
    tong_tam_ung: 0,
    list_tien_chi: [],
    list_tam_ung: []
  },
  formState: {
    edit: false,
    open: false,
    ngay_thang: roundTimeQuarterHour(),
    type: 'tam_ung',
    so_tien: 0
  }
};

const WalletManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DRIVER: {
      return {
        ...state,
        drivers: action.drivers
      };
    }

    case actionTypes.SET_FILTER: {
      return {
        ...state,
        filter: { ...state.filter, ...action.filter }
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_DRIVER: {
      return {
        ...state,
        selectedDriver: action.selected
      };
    }

    case actionTypes.SET_RESPONSE: {
      return {
        ...state,
        loading: false,
        response: action.response
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default WalletManagementReducer;
