import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  trucks: [],
  selectedTrucks: [],
  showTruck: []
};

const liveCameraReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_TRUCKS: {
      return {
        ...state,
        trucks: action.trucks,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_TRUCK: {
      return {
        ...state,
        selectedTrucks: action.selectedTrucks
      };
    }

    case actionTypes.SET_SHOW_TRUCK: {
      return {
        ...state,
        showTruck: action.showTruck
      };
    }

    default: {
      return state;
    }
  }
};

export default liveCameraReducer;
