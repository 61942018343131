import { salaryListApi, salaryListCalculateApi } from 'apis';

const prefix = 'salary';

export const SET_VEHICLE = `${prefix}SET_VEHICLE`;
export const SET_SORT_TYPE = `${prefix}SET_SORT_TYPE`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_VEHICLE = `${prefix}SET_SELECTED_VEHICLE`;
export const SET_FILTER = `${prefix}SET_FILTER`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_VEHICLE`;
export const SET_FILTER_FORM = `${prefix}SET_FILTER_FORM`;

export const setData = vehicles => dispatch => {
  dispatch({
    type: SET_VEHICLE,
    vehicles
  });
};

export const setSortType = sortType => dispatch => {
  dispatch({
    type: SET_SORT_TYPE,
    sortType
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getSalary = data => async dispatch => {
  dispatch(setLoading(true));
  try {
    const res = await salaryListApi(data);
    console.log(res);
    dispatch(setData(res));
  } catch (error) {
    console.log('get drivers error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedVehicle = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_VEHICLE,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER,
    data
  });
};

export const setFilterForm = data => dispatch => {
  if (data.selected) {
    window.localStorage.setItem(
      'tms_local_selected_list_salary_filter',
      JSON.stringify(data.selected)
    );
  }
  dispatch({
    type: SET_FILTER_FORM,
    data
  });
};

export const getFilterForm = () => async dispatch => {
  try {
    const list = await salaryListCalculateApi();
    const newList = list.map(item => item.name);
    const localList =
      window.localStorage.getItem('tms_local_list_excel_filter_salary') || '';
    const localSelectedList =
      window.localStorage.getItem(
        'tms_local_selected_list_salary_filter'
      ) || '';

    if (localList && JSON.parse(localList).length !== newList.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter_salary',
        JSON.stringify(newList)
      );
    }

    dispatch(
      setFilterForm({
        list: newList,
        selected: localSelectedList ? JSON.parse(localSelectedList) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm', error);
  }
};
