import { getVehiclesApi } from 'apis';

export const SET_VEHICLE = 'SET_VEHICLE';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE';
export const UPDATE_FORM = 'UPDATE_FORM_VEHICLE';

export const setVehicles = vehicles => dispatch => {
  dispatch({
    type: SET_VEHICLE,
    vehicles
  });
};

export const setSortType = sortType => dispatch => {
  dispatch({
    type: SET_SORT_TYPE,
    sortType
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getVehicles = (data) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const vehicles = await getVehiclesApi(data);
    dispatch(setVehicles(vehicles));
  } catch (error) {
    console.log('get vehicles error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedVehicle = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_VEHICLE,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
