export const UPDATE_WAY_POINTS = 'UPDATE_WAY_POINTS';
export const UPDATE_MARKER = 'UPDATE_MARKER';
export const SET_DRIVERS = 'SET_DRIVERS';
export const SET_DRIVING_TIME = 'SET_DRIVING_TIME';
export const SET_DRIVER_REPORT = 'SET_DRIVER_REPORT';
export const SET_UNSAFE_BEHAVIOR = 'SET_UNSAFE_BEHAVIOR';

export const updateWayPoints = wayPoints => dispatch =>
  dispatch({
    type: UPDATE_WAY_POINTS,
    wayPoints
  });

export const updateMarker = marker => dispatch =>
  dispatch({
    type: UPDATE_MARKER,
    marker
  });

export const setDrivers = drivers => dispatch =>
  dispatch({
    type: SET_DRIVERS,
    drivers
  });

export const setDrivingTime = drivingTime => dispatch =>
  dispatch({
    type: SET_DRIVING_TIME,
    drivingTime
  });

export const setDriverReport = driverReport => dispatch =>
  dispatch({
    type: SET_DRIVER_REPORT,
    driverReport
  });

export const setUnsafeBehavior = data => dispatch =>
  dispatch({
    type: SET_UNSAFE_BEHAVIOR,
    data
  });
