import * as actionTypes from 'actions/orderSwitchStationActions';
function roundTimeQuarterHour() {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
}
const initDetailInformation = {
  assistantName: '',
  assistantFee: 0,
  plate: '',
  driver1: '',
  driver2: '',
  driver3: '',
  totalFee: 0,
  tong_km: 0,
  don_gia: 0,
  tripType: '',
  route: '',
  itemDescription: '',
  itemWeight: '',
  itemNumber: '',
  itemCBM: '',
  inputItemPlate: '',
  outputItemPlate: '',
  inputItemTime: roundTimeQuarterHour(),
  outputItemTime: roundTimeQuarterHour(),
  senderName: 'Công ty Hồng Nam',
  receiverName: 'Công ty Hồng Nam',
  phi_xang_dau: 0,
  phi_cau_duong: 0,
  ve_cau_pha: 0,
  phi_boc_xep: 0,
  phi_sua_chua: 0,
  luong_lai_xe: 0,
  chi_phi_khac: 0,
  ly_do: ''
};

const initValidateDetail = {
  senderName: false,
  receiverName: false,
  assistantName: false,
  tripType: false,
  itemDescription: false,
  itemWeight: false,
  inputItemPlate: false,
  outputItemPlate: false
};

const initValidate = {
  assistantName: false,
  assistantFee: false,
  ...initValidateDetail
};

const initSuggestions = {
  bien_so_xe: [],
  ten_thau_phu: [],
  ten_lai_xe: [],
  mo_ta_hang_hoa: [],
  trong_luong: [],
  so_luong: [],
  cbm: [],
  noi_lay_hang: [],
  noi_tra_hang: []
};

const initialState = {
  generalInformation: {
    checkType: false,
    numberDriver: 2
  },
  detailInformation: initDetailInformation,
  validate: initValidate,
  suggestion: initSuggestions,
  suggestion2: {}
};

const orderSwitchStationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GENERAL_INFO: {
      return {
        ...state,
        generalInformation: {
          ...state.generalInformation,
          ...action.data
        },
        validate: { ...state.validate, [Object.keys(action.data)[0]]: false }
      };
    }

    case actionTypes.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.activeStep
      };
    }

    case actionTypes.SET_COMPLETED: {
      return {
        ...state,
        completed: action.completed
      };
    }

    case actionTypes.UPDATE_DETAIL_INFO: {
      return {
        ...state,
        detailInformation: {
          ...(action.isNew ? initDetailInformation : state.detailInformation),
          ...action.data
        },
        validate: { ...state.validate, [Object.keys(action.data)[0]]: false }
      };
    }

    case actionTypes.UPDATE_VALIDATE: {
      return {
        ...state,
        validate: { ...state.validate, ...action.data }
      };
    }

    case actionTypes.SET_DETAIL_INFO: {
      return {
        ...state,
        detailInformation: action.detailInformation,
        generalInformation: action.generalInformation
      };
    }

    case actionTypes.RESET_ORDER_SWITCH_STATION: {
      return initialState;
    }

    case actionTypes.SET_SUGGEST: {
      return {
        ...state,
        suggestion: action.suggestion,
        suggestion2: action.suggestion2
      };
    }

    default: {
      return state;
    }
  }
};

export default orderSwitchStationReducer;
