import { getRoutesApi } from 'apis';

export const SET_ROUTES = 'SET_ROUTES';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE';
export const UPDATE_FORM = 'UPDATE_FORM';

export const setRoutes = listRoute => dispatch => {
  dispatch({
    type: SET_ROUTES,
    listRoute
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getRoutes = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const routes = await getRoutesApi();
    dispatch(setRoutes(routes));
  } catch (error) {
    console.log('get route error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedRoute = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ROUTE,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
