import * as actionTypes from 'actions/scheduleActions';

const initialState = {
  loading: false,
  schedules: [],
  selectedTrucks: [],
  formAddTruck: {
    open: false
  }
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCHEDULES: {
      return {
        ...state,
        schedules: action.schedules,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    default: {
      return state;
    }
  }
};

export default scheduleReducer;
