import {
  getOrderWaitingApproveApi,
  createWithOrderWaitingApproveApi,
  getSuggestApi,
  updateWithOrderWaitingApproveApi
} from 'apis';
import { first } from 'lodash';
import moment from 'moment';

const prefix = 'OrderWaitingApprove';

export const UPDATE_DETAIL_INFO = `${prefix}UPDATE_DETAIL_INFO`;
export const ADD_ORDER = `${prefix}ADD_ORDER`;
export const SET_DETAIL_CHOOSE = `${prefix}SET_DETAIL_CHOOSE`;
export const RESET_TRIP = `${prefix}RESET_TRIP`;
export const SET_DETAIL_INFO = `${prefix}SET_DETAIL_INFO`;
export const UPDATE_VALIDATE = `${prefix}UPDATE_VALIDATE`;
export const DELETE_ORDER = `${prefix}DELETE_ORDER`;
export const DUPLICATE_ORDER = `${prefix}DUPLICATE_ORDER`;
export const SET_SUGGEST = `${prefix}SET_SUGGEST`;

export const updateDetailInfo = data => dispatch => {
  dispatch({
    type: UPDATE_DETAIL_INFO,
    data
  });
};

export const addOrder = () => dispatch => {
  dispatch({
    type: ADD_ORDER
  });
};

export const setDetailChoose = data => dispatch => {
  dispatch({
    type: SET_DETAIL_CHOOSE,
    data
  });
};

export const resetTrip = () => dispatch => {
  dispatch({
    type: RESET_TRIP
  });
};

export const updateValidate = data => dispatch => {
  dispatch({
    type: UPDATE_VALIDATE,
    data
  });
};

export const deleteOrder = () => dispatch => {
  dispatch({
    type: DELETE_ORDER
  });
};

export const duplicateOrder = () => dispatch => {
  dispatch({
    type: DUPLICATE_ORDER
  });
};

export const getSuggest = () => async dispatch => {
  const suggestion = await getSuggestApi();
  dispatch({
    type: SET_SUGGEST,
    suggestion
  });
};

export const getOrderWaitingApproveInfo = id => async dispatch => {
  try {
    const tripInfo = await getOrderWaitingApproveApi(id);
    dispatch({
      type: SET_DETAIL_INFO,
      detailInformation: [tripInfo]
    });
  } catch (error) {
    console.log(error);
  }
};

export const createOrderWaitingApprove = (
  isEdit,
  info,
  id
) => async dispatch => {
  try {
    let res;
    if (isEdit)
      res = await updateWithOrderWaitingApproveApi({
        ...first(info),
        ...{
          ngay_gio_lay_hang: moment(info[0].ngay_gio_lay_hang).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          ngay_gio_tra_hang: moment(info[0].ngay_gio_tra_hang).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          id_don_hang: id
        }
      });
    else
      res = await createWithOrderWaitingApproveApi(
        info.map(item => {
          return {
            ...item,
            ...{
              ngay_gio_lay_hang: moment(item.ngay_gio_lay_hang).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              ngay_gio_tra_hang: moment(item.ngay_gio_tra_hang).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            }
          };
        })
      );

    dispatch(resetTrip);
    alert(res.message || `${isEdit ? 'Cập nhật' : 'Tạo'} đơn thành công`);
    window.location.href = '/order-waiting-approve-management';
  } catch (error) {
    console.log(error);
    alert(
      `Có lỗi trong quá trình ${
        isEdit ? 'cập nhật' : 'tạo'
      } đơn, vui lòng thử lại sau`
    );
  }
};
