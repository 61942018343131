import * as actionTypes from 'actions';
import moment from 'moment';

const initialState = {
  mapLoading: false,
  filterData: {
    start: moment()
      .subtract(moment.duration('48:00:00'))
      .toDate(),
    end: moment().toDate()
  },
  listExcel: []
};

const excelReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filterData: { ...state.filterData, ...action.filterData }
      };
    }

    case actionTypes.SET_LIST_EXCEL: {
      return {
        ...state,
        listExcel: action.listExcel
      };
    }

    default: {
      return state;
    }
  }
};

export default excelReportReducer;
