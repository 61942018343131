import { listWarningApi, deleteWarningApi } from 'apis';

export const SET_WARNING = 'SET_WARNING';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_WARNING = 'SET_SELECTED_WARNING';
export const OPEN_FORM_ADD_WARNING = 'OPEN_FORM_ADD_WARNING';
export const DELETE_WARNING = 'DELETE_WARNING';
export const SET_SHOW_WARNING = 'SET_SHOW_WARNING';
export const SET_ADD_NEW_WARNING = 'SET_ADD_NEW_WARNING';

export const getWarnings = () => async dispatch => {
  try {
    dispatch(setLoading(true));
    const warnings = await listWarningApi();
    dispatch({
      type: SET_WARNING,
      warnings
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setSelectedWarning = selectedWarning => dispatch => {
  dispatch({
    type: SET_SELECTED_WARNING,
    selectedWarning
  });
};

export const openFormAddWarning = formAddWarning => dispatch => {
  dispatch({
    type: OPEN_FORM_ADD_WARNING,
    formAddWarning
  });
};

export const deleteWarning = warnings => async dispatch => {
  try {
    await deleteWarningApi(warnings);
    dispatch({
      type: DELETE_WARNING
    });
    dispatch(getWarnings());
  } catch (error) {
    console.log('Delete warning', error);
  }
};

export const setShowWarning = showWarning => dispatch => {
  dispatch({
    type: SET_SHOW_WARNING,
    showWarning
  });
};

export const setAddWarningFlag = flag => dispatch => {
  dispatch({
    type: SET_ADD_NEW_WARNING,
    flag
  });
};
