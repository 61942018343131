export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_LIST_EXCEL = 'SET_LIST_EXCEL';

export const setFilterData = filterData => dispatch =>
  dispatch({
    type: SET_FILTER_DATA,
    filterData
  });

export const setListExcel = listExcel => dispatch =>
  dispatch({
    type: SET_LIST_EXCEL,
    listExcel
  });
