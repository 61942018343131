import { truckListApi } from 'apis';

export const GET_TRUCK_LIST = 'GET_TRUCK_LIST';

export const getTruckList = () => async dispatch => {
  const trucks = await truckListApi();
  dispatch({
    type: GET_TRUCK_LIST,
    trucks
  });
};
