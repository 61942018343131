import * as actionTypes from 'actions';

const initialState = {
  mapLoading: false,
  searchFileData: '',
  fileData: [],
  addContact: {
    open: false,
    contract: {}
  }
};

const excelReportDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILE_DATA: {
      return {
        ...state,
        fileData: action.fileData
      };
    }

    case actionTypes.SET_SEARCH_FILE_DATA: {
      return {
        ...state,
        searchFileData: action.searchFileData
      };
    }
    case actionTypes.OPEN_ADD_CONTRACT: {
      return {
        ...state,
        addContact: { ...state.addContact, ...{ open: action.open } }
      };
    }

    default: {
      return state;
    }
  }
};

export default excelReportDetailReducer;
