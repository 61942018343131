import {
  getListCustomerApi,
  getCompaniesApi,
  getFilterOrdersApi,
  formOrderList,
  getFilterOrdersApiTaiExcel,
  bsxApi,
  getFilterApiTaiExcelVECTTRuck
} from 'apis';
import { setTrips } from 'actions/tripManagementActions';
import { downloadAnhKhachHang } from 'apis';

const prefix = 'filterOrder';

export const GET_FILTER_DATA = `${prefix}_GET_FILTER_DATA`;
export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const SET_FILTER_FORM = `${prefix}_SET_FILTER_FORM`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_ORDERS = `${prefix}_SET_ORDERS`;
export const SET_LINK = `${prefix}_SET_LINK`;

export const getFilterData = () => async dispatch => {
  const companies = await getCompaniesApi();
  const customers = await getListCustomerApi();
  const bsx = await bsxApi();
  dispatch({
    type: GET_FILTER_DATA,
    companies,
    customers,
    bsx
  });
};

export const setFilterForm = data => dispatch => {
  if (data.selected1) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter_order1',
      JSON.stringify(data.selected1)
    );
  }
  if (data.selected2) {
    window.localStorage.setItem(
      'tms_local_selected_list_excel_filter_order2',
      JSON.stringify(data.selected2)
    );
  }
  dispatch({
    type: SET_FILTER_FORM,
    data
  });
};

export const getFilterForm = () => async dispatch => {
  try {
    const res = await formOrderList();
    const newList1 = res.tab1;
    const localList1 =
      window.localStorage.getItem('tms_local_list_excel_filter_order') || '';
    const localSelectedList1 =
      window.localStorage.getItem(
        'tms_local_selected_list_excel_filter_order1'
      ) || '';

    if (localList1 && JSON.parse(localList1).length !== newList1.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter_order',
        JSON.stringify(newList1)
      );
    }

    const newList2 = res.tab2;
    const localList2 =
      window.localStorage.getItem('tms_local_list_excel_filter2') || '';
    const localSelectedList2 =
      window.localStorage.getItem(
        'tms_local_selected_list_excel_filter_order2'
      ) || '';

    if (localList2 && JSON.parse(localList2).length !== newList2.length) {
      window.localStorage.setItem(
        'tms_local_list_excel_filter2',
        JSON.stringify(newList2)
      );
    }
    dispatch(
      setFilterForm({
        list1: newList1,
        list2: newList2,
        selected1: localSelectedList1 ? JSON.parse(localSelectedList1) : [],
        selected2: localSelectedList2 ? JSON.parse(localSelectedList2) : []
      })
    );
  } catch (error) {
    console.log('getFilterForm ', error);
  }
};

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setOrders = listOrder => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder
  });
};

export const setLink = link => dispatch => {
  dispatch({
    type: SET_LINK,
    link
  });
};

export const getFilterOrder = (
  type,
  listFilter,
  startTime,
  endTime,
  list_form
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const orders = await getFilterOrdersApi(
      type,
      listFilter,
      startTime,
      endTime,
      list_form
    );
    if (orders.message != undefined) {
      alert(orders.message);
      // window.location.reload()
    } else {
      if (type === 'bien_so_xe') {
        const data = {
          chuyen_di_array: orders.data,
          download_link: orders.link
        };
        dispatch(setTrips(data));
        dispatch(setLink(orders.link));
      } else {
        dispatch(setOrders(orders));
      }
    }
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const getFilterOrderTaiExcel = (
  type,
  listFilter,
  startTime,
  endTime,
  list_form
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const orders = await getFilterOrdersApiTaiExcel(
      type,
      listFilter,
      startTime,
      endTime,
      list_form
    );
    if (orders.message != undefined) {
      alert(orders.message);
      // window.location.reload()
    } else {
      window.open(orders.link);
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const getFilterTaiVECTTRUCK = (
  type,
  listFilter,
  startTime,
  endTime,
  list_form
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const orders = await getFilterApiTaiExcelVECTTRuck(
      type,
      listFilter,
      startTime,
      endTime,
      list_form
    );
    if (orders.message != undefined) {
      alert(orders.message);
      // window.location.reload()
    } else {
      window.open(orders.link);
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const handleFilterTaiAnhAction = (
  status,
  listFilter,
  startDate,
  endDate
) => async dispatch => {
  dispatch(setLoading(true));

  try {
    const res = await downloadAnhKhachHang(
      status,
      listFilter,
      startDate,
      endDate
    );
    if (res.message != undefined) {
      alert(res.message);
    } else {
      window.open(res.link);
    }
    dispatch(setLoading(false));
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};
