import moment from 'moment';
import {
  getOrderSSInfoApi,
  createOrderSSApi,
  getSuggestApi,
  updateOrderSSApi,
  getSuggestOrderSSApi
} from 'apis';

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_COMPLETED = 'SET_COMPLETED';
export const SET_GENERAL_INFO = 'SET_ORDER_GENERAL_INFO';
export const UPDATE_DETAIL_INFO = 'UPDATE_ORDER_DETAIL_INFO';
export const ADD_ORDER = 'ADD_ORDER';
export const SET_DETAIL_CHOOSE = 'SET_DETAIL_CHOOSE';
export const RESET_ORDER_SWITCH_STATION = 'RESET_ORDER_SWITCH_STATION';
export const SET_DETAIL_INFO = 'SET_ORDER_DETAIL_INFO';
export const UPDATE_VALIDATE = 'UPDATE_ORDER_VALIDATE';
export const DELETE_ORDER = 'DELETE_ORDER_SS';
export const DUPLICATE_ORDER = 'DUPLICATE_ORDER_SS';
export const SET_SUGGEST = 'SET_SUGGEST';
export const SET_IMAGES = 'SET_IMAGES';

export const setActiveStep = activeStep => dispatch => {
  dispatch({
    type: SET_ACTIVE_STEP,
    activeStep
  });
};

export const setCompleted = completed => dispatch => {
  dispatch({
    type: SET_COMPLETED,
    completed
  });
};

export const setGeneralInfo = data => dispatch => {
  dispatch({
    type: SET_GENERAL_INFO,
    data
  });
};

export const updateDetailInfo = (data, isNew) => dispatch => {
  dispatch({
    type: UPDATE_DETAIL_INFO,
    data,
    isNew
  });
};

export const addOrder = () => dispatch => {
  dispatch({
    type: ADD_ORDER
  });
};

export const setDetailChoose = data => dispatch => {
  dispatch({
    type: SET_DETAIL_CHOOSE,
    data
  });
};

export const resetOrderSwitchStation = () => dispatch => {
  dispatch({
    type: RESET_ORDER_SWITCH_STATION
  });
};

const getDetailOrder = order => {
  return {
    ...order,
    assistantName: order.ten_thau_phu,
    assistantFee: order.chi_phi_thau_phu,
    plate: order.bien_so_xe,
    totalFee: order.tong_chi_phi,
    tong_km: order.tong_km,
    don_gia: order.don_gia,
    driver1: order.lai_xe_1,
    driver2: order.lai_xe_2,
    driver3: order.lai_xe_3,
    tripType: order.loai_don_hang,
    route: order.tuyen_duong,
    itemDescription: order.mo_ta_hang_hoa,
    itemWeight: order.trong_luong,
    itemNumber: order.so_luong,
    itemCBM: order.cbm,
    inputItemPlate: order.noi_lay_hang,
    outputItemPlate: order.noi_tra_hang,
    inputItemTime: order.ngay_gio_lay_hang,
    outputItemTime: order.ngay_gio_tra_hang,
    senderName: order.ten_nguoi_gui,
    receiverName: order.ten_nguoi_nhan
  };
};

export const getOrderInfo = id => async dispatch => {
  try {
    const order = await getOrderSSInfoApi(id);
    const generalInformation = {
      numberDriver: order.lai_xe_3 === '' ? 2 : 3,
      checkType: order.ten_thau_phu && order.ten_thau_phu !== '',
      chuyen_di_image: order.chuyen_di_image
    };
    const detailInformation = getDetailOrder(order);
    dispatch({
      type: SET_DETAIL_INFO,
      generalInformation,
      detailInformation
    });
  } catch (error) {
    console.log(error);
  }
};

const convertData = info => {
  const {
    assistantFee,
    phi_xang_dau,
    phi_cau_duong,
    ve_cau_pha,
    phi_boc_xep,
    phi_sua_chua,
    luong_lai_xe,
    chi_phi_khac
  } = info;

  const tong_chi_phi =
    Number(assistantFee) +
    Number(phi_xang_dau) +
    Number(phi_cau_duong) +
    Number(ve_cau_pha) +
    Number(phi_boc_xep) +
    Number(phi_sua_chua) +
    Number(luong_lai_xe) +
    Number(chi_phi_khac);
  return {
    ...info,
    id_chuyen_di: info.id,
    ten_thau_phu: info.assistantName,
    chi_phi_thau_phu: info.assistantFee,
    bien_so_xe: info.plate,
    tong_chi_phi,
    tong_km: Number(info.tong_km),
    don_gia: Number(info.don_gia),
    lai_xe_1: info.driver1,
    lai_xe_2: info.driver2,
    lai_xe_3: info.driver3,
    loai_don_hang: info.tripType,
    tuyen_duong: info.route,
    mo_ta_hang_hoa: info.itemDescription,
    trong_luong: info.itemWeight,
    so_luong: Number(info.itemNumber),
    cbm: info.itemCBM,
    noi_lay_hang: info.inputItemPlate,
    noi_tra_hang: info.outputItemPlate,
    ngay_gio_lay_hang: moment(info.inputItemTime).format('YYYY-MM-DD HH:mm:ss'),
    ngay_gio_tra_hang: moment(info.outputItemTime).format(
      'YYYY-MM-DD HH:mm:ss'
    ),
    ten_nguoi_gui: info.senderName,
    ten_nguoi_nhan: info.receiverName
  };
};

export const createOrder = (info, isEdit, router) => async dispatch => {
  try {
    const data = convertData(info);
    if (isEdit) await updateOrderSSApi(data);
    else await createOrderSSApi(data);

    dispatch(resetOrderSwitchStation);
    router.history.push('/trip-management');
  } catch (error) {
    console.log(error);
  }
};

export const updateValidate = data => dispatch => {
  dispatch({
    type: UPDATE_VALIDATE,
    data
  });
};

export const deleteOrder = () => dispatch => {
  dispatch({
    type: DELETE_ORDER
  });
};

export const duplicateOrder = () => dispatch => {
  dispatch({
    type: DUPLICATE_ORDER
  });
};

export const getSuggest = () => async dispatch => {
  const suggestion = await getSuggestApi();
  const suggestion2 = await getSuggestOrderSSApi();
  dispatch({
    type: SET_SUGGEST,
    suggestion,
    suggestion2
  });
};
