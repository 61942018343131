import * as actionTypes from 'actions/excelManagementActions';
import moment from 'moment';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(7, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(7, 'days')
    .toDate()
};

const initialState = {
  excels: [],
  loading: false,
  filter: initFilter,
  selectedExcel: []
};

const excelManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }
    case actionTypes.SET_EXCEL: {
      return {
        ...state,
        excels: action.excels,
        loading: false,
        selectedExcel: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_EXCEL: {
      return {
        ...state,
        selectedExcel: action.selected
      };
    }

    default: {
      return state;
    }
  }
};

export default excelManagementReducer;
