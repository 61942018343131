import * as actionTypes from 'actions/routeManagementActions';

const initialState = {
  listRoute: [],
  loading: false,
  selectedRoute: [],
  formState: { open: false, edit: false }
};

const routeManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROUTES: {
      return {
        ...state,
        listRoute: action.listRoute,
        loading: false,
        selectedRoute: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_ROUTE: {
      return {
        ...state,
        selectedRoute: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default routeManagementReducer;
