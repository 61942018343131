import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  trucks: [],
  selectedTrucks: [],
  formAddTruck: {
    open: false
  }
};

const truckReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRUCKS: {
      return {
        ...state,
        trucks: action.trucks,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_TRUCK: {
      return {
        ...state,
        selectedTrucks: action.selectedTrucks
      };
    }

    case actionTypes.OPEN_FORM_ADD_TRUCK: {
      return {
        ...state,
        formAddTruck: {
          ...state.formAddTruck,
          ...{ open: action.open, truck: action.truck }
        }
      };
    }

    case actionTypes.DELETE_TRUCK: {
      return {
        ...state,
        selectedTrucks: []
      };
    }

    default: {
      return state;
    }
  }
};

export default truckReducer;
