import { currentTruckListApi } from 'apis';

export const SET_CURRENT_TRUCKS = 'SET_CURRENT_TRUCKS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_TRUCK = 'SET_SELECTED_TRUCK';
export const SET_SHOW_TRUCK = 'SET_SHOW_TRUCK';

export const getCurrentTrucks = () => async dispatch => {
  try {
    dispatch(setLiveCameraLoading(true));
    const trucks = await currentTruckListApi();

    dispatch({
      type: SET_CURRENT_TRUCKS,
      trucks
    });
  } catch (error) {
    dispatch(setLiveCameraLoading(false));
  }
};

export const setLiveCameraLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setSelectedTruck = selectedTrucks => dispatch => {
  dispatch({
    type: SET_SELECTED_TRUCK,
    selectedTrucks
  });
};

export const setShowTruck = showTruck => dispatch => {
  dispatch({
    type: SET_SHOW_TRUCK,
    showTruck
  });
};
