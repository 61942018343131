import { listRuleApi, deleteRuleApi } from 'apis';

export const SET_RULES = 'SET_RULES';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_RULE = 'SET_SELECTED_RULE';
export const OPEN_FORM_ADD_RULE = 'OPEN_FORM_ADD_RULE';
export const DELETE_RULE = 'DELETE_RULE';

export const getRules = plate => async dispatch => {
  try {
    dispatch(setLoading(true));
    const rules = await listRuleApi(plate);
    dispatch({
      type: SET_RULES,
      rules
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setSelectedRule = selectedRules => dispatch => {
  dispatch({
    type: SET_SELECTED_RULE,
    selectedRules
  });
};

export const openFormAddRule = (open, rule) => dispatch => {
  dispatch({
    type: OPEN_FORM_ADD_RULE,
    open,
    rule
  });
};

export const deleteRule = rules => async dispatch => {
  try {
    await deleteRuleApi(rules);
    dispatch({
      type: DELETE_RULE,
      rules
    });
  } catch (error) {
    console.log('Delete rule', error);
  }
};
