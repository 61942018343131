import * as actionTypes from 'actions/orderWaitingApproveActions';
function roundTimeQuarterHour() {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
}
const initDetailInformation = {
  id_don_hang: '',
  ten_nguoi_gui: '',
  ten_nguoi_nhan: '',
  sdt_nguoi_gui: '',
  dia_chi_nguoi_gui: '',
  dia_chi_nguoi_nhan: '',
  sdt_nguoi_nhan: '',
  ten_cong_ty: '',
  loai_don_hang: '',
  loai_hang_hoa: '',
  mo_ta_hang_hoa: '',
  trong_luong: '',
  so_luong: '',
  cbm: '',
  noi_lay_hang: '',
  ngay_gio_lay_hang: roundTimeQuarterHour(),
  noi_tra_hang: '',
  ngay_gio_tra_hang: roundTimeQuarterHour(),
  cod: '',
  cuoc_van_chuyen: 0,
  thanh_toan: '',
  hinh_thuc_thanh_toan: '',
  nguoi_thu_tien: '',
  nguoi_thanh_toan: '',
  vat: false,
  ghi_chu: '',
  list_anh: []
};

const initValidateDetail = {
  ten_nguoi_gui: false,
  ten_nguoi_nhan: false,
  loai_don_hang: false,
  loai_hang_hoa: false,
  mo_ta_hang_hoa: false,
  trong_luong: false,
  noi_lay_hang: false,
  noi_tra_hang: false,
  validateDetail: []
};

const initValidate = {
  assistantName: false,
  assistantFee: false,
  ...initValidateDetail
};

const initSuggestions = {
  bien_so_xe: [],
  ten_lai_xe: [],
  ten_nguoi_gui: [],
  sdt_nguoi_gui: [],
  dia_chi_nguoi_gui: [],
  ten_nguoi_nhan: [],
  sdt_nguoi_nhan: [],
  dia_chi_nguoi_nhan: [],
  ten_thau_phu: [],
  loai_hang_hoa: [],
  mo_ta_hang_hoa: [],
  trong_luong: [],
  so_luong: [],
  cbm: [],
  noi_lay_hang: [],
  noi_tra_hang: [],
  cod: [],
  cuoc_van_chuyen: [],
  nguoi_thu_tien: [],
  nguoi_thanh_toan: [],
  ghi_chu: []
};

const initialState = {
  images: [],
  detailChoose: 0,
  detailInformation: [initDetailInformation],
  list_form_chi_phi: [],
  validate: initValidate,
  suggestion: initSuggestions
};

const orderWaitingApproveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ORDER: {
      return {
        ...state,
        detailInformation: [...state.detailInformation, initDetailInformation],
        detailChoose: state.detailInformation.length,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.UPDATE_DETAIL_INFO: {
      const newInfo = state.detailInformation.map((data, key) => {
        if (key === state.detailChoose) {
          return { ...data, ...action.data };
        }
        return data;
      });
      return {
        ...state,
        detailInformation: newInfo,
        validate: { ...state.validate, [Object.keys(action.data)[0]]: false }
      };
    }

    case actionTypes.SET_DETAIL_CHOOSE: {
      return {
        ...state,
        detailChoose: action.data,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.UPDATE_VALIDATE: {
      return {
        ...state,
        validate: { ...state.validate, ...action.data }
      };
    }

    case actionTypes.DELETE_ORDER: {
      return {
        ...state,
        detailInformation: state.detailInformation.filter(
          (value, key) => key !== state.detailChoose
        ),
        detailChoose: 0,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.SET_DETAIL_INFO: {
      return {
        ...state,
        detailInformation: action.detailInformation
      };
    }

    case actionTypes.DUPLICATE_ORDER: {
      const newInfo = state.detailInformation.find(
        (value, key) => key === state.detailChoose
      );
      return {
        ...state,
        detailInformation: [...state.detailInformation, newInfo],
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.RESET_TRIP: {
      return initialState;
    }

    case actionTypes.SET_SUGGEST: {
      return {
        ...state,
        suggestion: action.suggestion
      };
    }

    default: {
      return state;
    }
  }
};

export default orderWaitingApproveReducer;
