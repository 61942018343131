import { getCompanysApi } from 'apis';

const prefix = 'companyManagement';

export const SET_COMPANY = `${prefix}SET_COMPANY`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_COMPANY = `${prefix}SET_SELECTED_COMPANY`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_COMPANY`;
export const UPDATE_IMAGE = `${prefix}UPDATE_IMAGE_COMPANY`;

export const setCompanys = companys => dispatch => {
  dispatch({
    type: SET_COMPANY,
    companys
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getCompanys = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const companys = await getCompanysApi();
    dispatch(setCompanys(companys));
  } catch (error) {
    console.log('get companys error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedCompany = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_COMPANY,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const updateFile = files => dispatch => {
  dispatch({
    type: UPDATE_IMAGE,
    files
  });
};
