import { getOrdersApi } from 'apis';

export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setOrders = listOrder => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getOrders = (status, startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const orders = await getOrdersApi(status, startDate, endDate);
    if (orders.message != undefined) {
      alert(orders.message)
      dispatch(setLoading(false));
    }
    else {
      dispatch(setOrders(orders));
    }
  } catch (error) {
    console.log('get order error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedOrder = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ORDER,
    selected
  });
};
