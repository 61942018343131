import {
  getOrdersWaitingApproveApi,
  getOrdersWaitingApproveImportApi
} from 'apis';

const prefix = 'orderWaitingApproveManagement';

export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const SET_ORDERS = `${prefix}_SET_ORDERS`;
export const SET_COLOR = `${prefix}_SET_COLOR`;

export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_SELECTED_ORDER = `${prefix}_SET_SELECTED_ORDER`;

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setOrders = listOrder => dispatch => {
  dispatch({
    type: SET_ORDERS,
    listOrder
  });
};

export const setColor = color => dispatch => {
  dispatch({
    type: SET_COLOR,
    color
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getOrders = (
  status,
  startDate,
  endDate,
  importOrder
) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const orders = importOrder
      ? await getOrdersWaitingApproveImportApi(status, startDate, endDate)
      : await getOrdersWaitingApproveApi(status, startDate, endDate);
    dispatch(setOrders(orders.data));
    dispatch(setColor(orders.color));
  } catch (error) {
    console.log('get order waiting api error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedOrder = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ORDER,
    selected
  });
};
