import * as actionTypes from 'actions/VehicleManagementActions';

const initialState = {
  vehicles: [],
  loading: false,
  selectedVehicle: [],
  formState: { open: false, edit: false },
  sortType: 'Không lọc'
};

const VehicleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VEHICLE: {
      return {
        ...state,
        vehicles: action.vehicles,
        loading: false,
        selectedVehicle: []
      };
    }

    case actionTypes.SET_SORT_TYPE: {
      return {
        ...state,
        sortType: action.sortType,
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default VehicleManagementReducer;
