import * as actionTypes from 'actions/expensesManagementActions';

const initialState = {
  expensess: [],
  loading: false,
  selectedExpenses: [],
  fixedExpensess: [],
  loadingFixedExpress: false,
  selectedFixedExpenses: [],
  formState: { open: false, edit: false },
  formStateFixedExpress: { open: false, edit: false }
};

const expensesManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXPENSES: {
      return {
        ...state,
        expensess: action.expensess,
        loading: false,
        selectedExpenses: []
      };
    }

    case actionTypes.SET_FIXED_EXPENSES: {
      return {
        ...state,
        fixedExpensess: action.fixedExpensess,
        loadingFixedExpress: false,
        selectedFixedExpenses: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_LOADING_FIXEX_EXPRESS: {
      return {
        ...state,
        loadingFixedExpress: action.loadingFixedExpress
      };
    }

    case actionTypes.SET_SELECTED_EXPENSES: {
      return {
        ...state,
        selectedExpenses: action.selected
      };
    }

    case actionTypes.SET_SELECTED_FIXED_EXPENSES: {
      return {
        ...state,
        selectedFixedExpenses: action.selectedFixedExpenses
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }
    case actionTypes.UPDATE_FORM_FIXED_EXPRESS: {
      return {
        ...state,
        formStateFixedExpress: { ...state.formStateFixedExpress, ...action.formStateFixedExpress }
      };
    }
    default: {
      return state;
    }
  }
};

export default expensesManagementReducer;
