import * as actionTypes from 'actions/noticeManagementActions';

const initialState = {
  listNotice: [],
  loading: false,
  selectedNotice: [],
  formState: { open: false, edit: false }
};

const noticeManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTICES: {
      return {
        ...state,
        listNotice: action.listNotice,
        loading: false,
        selectedNotice: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_NOTICE: {
      return {
        ...state,
        selectedNotice: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default noticeManagementReducer;
