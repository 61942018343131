import { scheduleApi } from 'apis';
import moment from 'moment';
const prefix = 'schedule';

export const SET_SCHEDULES = `${prefix}_SET_SCHEDULES`;
export const SET_LOADING = `${prefix}_SET_LOADING`;
export const SET_SELECTED_SCHEDULE = `${prefix}_SET_SELECTED_SCHEDULE`;
export const OPEN_FORM_ADD_SCHEDULE = `${prefix}_OPEN_FORM_ADD_SCHEDULE`;
export const DELETE_SCHEDULE = `${prefix}_DELETE_SCHEDULE`;

export const getSchedules = year => async dispatch => {
  try {
    dispatch(setLoading(true));
    const schedules = await scheduleApi(year);
    const newSchedules = schedules.map((schedule, key) => {
      return {
        id: key + 1,
        title: schedule.message,
        desc: schedule.message,
        color: schedule.color,
        start: moment(schedule.date).format(),
        allDay: true
      };
    });
    dispatch({
      type: SET_SCHEDULES,
      schedules: newSchedules
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};
