import * as actionTypes from 'actions';

const initialState = {
  mapLoading: false,
  wayPoints: [],
  markers: [],
  drivers: [],
  drivingTime: [],
  workingTime: {
    driverReport: []
  },
  unsafeBehavior: {
    abnormalActivity: [],
    linkVideo: null
  }
};

const drivingReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_WAY_POINTS: {
      return {
        ...state,
        wayPoints: action.wayPoints
      };
    }

    case actionTypes.UPDATE_MARKER: {
      return {
        ...state,
        markers: action.marker
      };
    }
    case actionTypes.SET_DRIVERS: {
      return {
        ...state,
        drivers: action.drivers
      };
    }

    case actionTypes.SET_DRIVING_TIME: {
      return {
        ...state,
        drivingTime: action.drivingTime
      };
    }
    case actionTypes.SET_DRIVER_REPORT: {
      return {
        ...state,
        workingTime: {
          ...state.workingTime,
          ...{ driverReport: action.driverReport }
        }
      };
    }
    case actionTypes.SET_UNSAFE_BEHAVIOR: {
      return {
        ...state,
        unsafeBehavior: {
          ...state.unsafeBehavior,
          ...action.data
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default drivingReportReducer;
