import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  rules: [],
  selectedRules: [],
  formAddRule: {
    open: false
  },
  plate: ''
};

const ruleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RULES: {
      return {
        ...state,
        rules: action.rules,
        loading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_RULE: {
      return {
        ...state,
        selectedRules: action.selectedRules
      };
    }

    case actionTypes.OPEN_FORM_ADD_RULE: {
      return {
        ...state,
        formAddRule: {
          ...state.formAddRule,
          ...{ open: action.open, rule: action.rule }
        }
      };
    }

    case actionTypes.DELETE_RULE: {
      return {
        ...state,
        selectedRules: []
      };
    }

    default: {
      return state;
    }
  }
};

export default ruleReducer;
