import * as actionTypes from 'actions/companyManagementActions';

const initialState = {
  companys: [],
  loading: false,
  selectedCompany: [],
  formState: { open: false, edit: false },
  files: []
};

const companyManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPANY: {
      return {
        ...state,
        companys: action.companys,
        loading: false,
        selectedCompany: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }
    case actionTypes.UPDATE_IMAGE: {
      return {
        ...state,
        files: action.files
      };
    }

    default: {
      return state;
    }
  }
};

export default companyManagementReducer;
