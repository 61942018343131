import moment from 'moment';
import { get } from 'lodash';

import * as actionTypes from 'actions/tripManagementActions';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(1, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(1, 'days')
    .toDate(),
  status: 'toan_bo'
};
const initialState = {
  listTrip: [],
  tabChoice: '',
  filter: initFilter,
  loading: false,
  loadingBox: false,
  selectedTrip: [],
  linkExcel: '',
  calendarColor: '',
  mat_dau_color: '',
  filterFormExcel: {
    open: false,
    list1: [],
    list2: [],
    selected1: [],
    selected2: [],
  },
  filterchiphi: initFilter,
  filterFormExcelChiPhi: {
    open: false,
    list: [],
    selected: []
  }
};

const tripManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }

    case actionTypes.SET_FILTER_FORM: {
      return {
        ...state,
        filterFormExcel: {
          ...state.filterFormExcel,
          ...action.data
        }
      };
    }

    case actionTypes.SET_FILTER_DATA_CHI_PHI: {
      return {
        ...state,
        filterchiphi: {
          ...state.filterchiphi,
          ...action.data
        }
      };
    }

    case actionTypes.SET_FILTER_FORM_CHI_PHI: {
      return {
        ...state,
        filterFormExcelChiPhi: {
          ...state.filterFormExcelChiPhi,
          ...action.data
        }
      };
    }

    case actionTypes.SET_TRIPS: {
      const trips = get(action, 'listTrip.chuyen_di_array', []);
      const linkExcel = get(action, 'listTrip.download_link', []);
      const calendarColor = get(action, 'listTrip.color', []);
      const mat_dau_color = get(action, 'listTrip.mat_dau_color', []);
      return {
        ...state,
        listTrip: trips,
        linkExcel: linkExcel,
        calendarColor,
        mat_dau_color,
        loading: false,
        selectedTrip: [],
        tabChoice: get(trips, '[0].bien_so_xe', 0)
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_LOADING_BOX: {
      return {
        ...state,
        loadingBox: action.loadingBox
      };
    }

    case actionTypes.SET_TAB_CHOICE: {
      return {
        ...state,
        tabChoice: action.tabChoice
      };
    }

    case actionTypes.SET_SELECTED_TRIP: {
      return {
        ...state,
        selectedTrip: action.selected
      };
    }

    case actionTypes.REMOVE_SELECTED_TRIP: {
      const { removed } = action;
      const listRemoveId = removed.map(trip => trip.id_chuyen_di);

      return {
        ...state,
        listTrip: state.listTrip.filter(
          ({ id_chuyen_di }) => !listRemoveId.includes(id_chuyen_di)
        ),
        selectedTrip: []
      };
    }

    default: {
      return state;
    }
  }
};

export default tripManagementReducer;
