import * as actionTypes from 'actions';

const initialState = {
  loading: false,
  markers: [],
  selectedMarkers: [],
  showMarkers: [],
  confirmForm: {
    points: null,
    open: false
  },
  uploadForm: {
    open: false,
    action: ''
  },
  addNewPoint: false
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MARKER_LIST: {
      return {
        ...state,
        markers: action.markers,
        loading: false
      };
    }

    case actionTypes.REMOVE_MARKER: {
      const { removed } = action;
      const listRemoveId = removed.map(marker => marker.id);

      return {
        ...state,
        markers: state.markers.filter(({ id }) => !listRemoveId.includes(id)),
        selectedMarkers: [],
        showMarkers: []
      };
    }

    case actionTypes.SET_SELECTED_MARKERS: {
      return {
        ...state,
        selectedMarkers: action.selectedMarkers
      };
    }

    case actionTypes.SET_SHOW_MARKERS: {
      return {
        ...state,
        showMarkers: action.showMarkers
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_CONFIRM_FORM: {
      return {
        ...state,
        confirmForm: { ...state.confirmForm, ...action.confirmForm }
      };
    }

    case actionTypes.SET_UPLOAD_FORM: {
      return {
        ...state,
        uploadForm: { ...state.uploadForm, ...action.uploadForm }
      };
    }
    case actionTypes.SET_ADD_POINT: {
      return {
        ...state,
        addNewPoint: action.addNewPoint
      };
    }

    default: {
      return state;
    }
  }
};

export default locationReducer;
