import * as actionTypes from 'actions/trashManagementActions';
import moment from 'moment';
import { roundTimeQuarterHour } from 'utils/format';

const initFilter = {
  startDate: moment(roundTimeQuarterHour())
    .subtract(7, 'days')
    .toDate(),
  endDate: moment(roundTimeQuarterHour())
    .add(7, 'days')
    .toDate()
};

const initialState = {
  trashs: [],
  excels: [],
  loading: false,
  loadingExcel: false,
  filter: initFilter,
  selectedTrash: []
};

const trashManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILTER_DATA: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.data
        }
      };
    }
    case actionTypes.SET_TRASH: {
      return {
        ...state,
        trashs: action.trashs,
        loading: false,
        selectedTrash: []
      };
    }

    case actionTypes.SET_EXCEL: {
      return {
        ...state,
        excels: action.excels,
        excelLoading: false
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_EXCEL_LOADING: {
      return {
        ...state,
        excelLoading: action.excelLoading
      };
    }

    case actionTypes.SET_SELECTED_TRASH: {
      return {
        ...state,
        selectedTrash: action.selected
      };
    }

    default: {
      return state;
    }
  }
};

export default trashManagementReducer;
