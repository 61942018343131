/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('views/Calendar'))
      },
      {
        path: '/changelog',
        exact: true,
        component: lazy(() => import('views/Changelog'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/invoices/:id',
        exact: true,
        component: lazy(() => import('views/InvoiceDetails'))
      },
      {
        path: '/kanban-board',
        exact: true,
        component: lazy(() => import('views/KanbanBoard'))
      },
      {
        path: '/mail',
        exact: true,
        component: lazy(() => import('views/Mail'))
      },
      {
        path: '/management/driving-report',
        exact: true,
        component: lazy(() => import('views/DrivingReport'))
      },
      {
        path: '/management/excel-report',
        exact: true,
        component: lazy(() => import('views/ExcelReport'))
      },
      {
        path: '/management/excel-report/:id/trip/:tripId',
        exact: true,
        component: lazy(() => import('views/ExcelReportTripDetails'))
      },
      {
        path: '/management/excel-report/:id',
        exact: true,
        component: lazy(() => import('views/ExcelReportDetails'))
      },
      {
        path: '/management/excel-report/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ExcelReportDetails'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('views/CustomerManagementDetails'))
      },
      {
        path: '/management/projects',
        exact: true,
        component: lazy(() => import('views/ProjectManagementList'))
      },
      {
        path: '/management/orders',
        exact: true,
        component: lazy(() => import('views/OrderManagementList'))
      },
      {
        path: '/management/orders/:id',
        exact: true,
        component: lazy(() => import('views/OrderManagementDetails'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/presentation',
        exact: true,
        component: PresentationView
      },
      {
        path: '/profile/:id',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/profile/:id/:tab',
        exact: true,
        component: lazy(() => import('views/Profile'))
      },
      {
        path: '/projects/create',
        exact: true,
        component: lazy(() => import('views/ProjectCreate'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('views/ProjectList'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      {
        path: '/social-feed',
        exact: true,
        component: lazy(() => import('views/SocialFeed'))
      },
      {
        path: '/location',
        exact: true,
        component: lazy(() => import('views/Location'))
      },
      {
        path: '/live-camera',
        exact: true,
        component: lazy(() => import('views/LiveCamera'))
      },
      {
        path: '/camera-play-back',
        exact: true,
        component: lazy(() => import('views/CameraPlayback'))
      },
      {
        path: '/management/image',
        exact: true,
        component: lazy(() => import('views/ImageManagement'))
      },
      {
        path: '/trucks',
        exact: true,
        component: lazy(() => import('views/Trucks'))
      },
      {
        path: '/warning',
        exact: true,
        component: lazy(() => import('views/Warning'))
      },
      {
        path: '/rule',
        exact: true,
        component: lazy(() => import('views/Rules'))
      },
      {
        path: '/paper',
        exact: true,
        component: lazy(() => import('views/Paper'))
      },
      // start
      {
        path: '/trip/:tab',
        exact: true,
        component: lazy(() => import('views/Trip'))
      },
      {
        path: '/trip/:tab/:id',
        exact: true,
        component: lazy(() => import('views/Trip'))
      },
      {
        path: '/order-switch-station/:tab',
        exact: true,
        component: lazy(() => import('views/OrderSwitchStation'))
      },
      {
        path: '/order-switch-station/:tab/:id',
        exact: true,
        component: lazy(() => import('views/OrderSwitchStation'))
      },
      {
        path: '/trip-management',
        exact: true,
        component: lazy(() => import('views/TripManagement'))
      },
      {
        path: '/filter-order',
        exact: true,
        component: lazy(() => import('views/FilterOrder'))
      },
      {
        path: '/excel-management',
        exact: true,
        component: lazy(() => import('views/ExcelManagement'))
      },
      {
        path: '/trash-management',
        exact: true,
        component: lazy(() => import('views/TrashManagement'))
      },
      {
        path: '/expenses-management',
        exact: true,
        component: lazy(() => import('views/ExpensesManagement'))
      },
      {
        path: '/order-management',
        exact: true,
        component: lazy(() => import('views/OrderManagement'))
      },
      {
        path: '/order-waiting-approve-management',
        exact: true,
        component: lazy(() => import('views/OrderWaitingApproveManagement'))
      },
      {
        path: '/wallet-management',
        exact: true,
        component: lazy(() => import('views/WalletManagement'))
      },
      {
        path: '/order-waiting-approve/:tab',
        exact: true,
        component: lazy(() => import('views/OrderWaitingApprove'))
      },
      {
        path: '/order-waiting-approve/:tab/:id',
        exact: true,
        component: lazy(() => import('views/OrderWaitingApprove'))
      },
      {
        path: '/order-switch-management',
        exact: true,
        component: lazy(() => import('views/OrderSwitchManagement'))
      },
      {
        path: '/route-management',
        exact: true,
        component: lazy(() => import('views/RouteManagement'))
      },
      {
        path: '/vehicle-management',
        exact: true,
        component: lazy(() => import('views/VehicleManagement'))
      },
      {
        path: '/empty-truck-management',
        exact: true,
        component: lazy(() => import('views/EmptyTruckManagement'))
      },
      {
        path: '/company-management',
        exact: true,
        component: lazy(() => import('views/CompanyManagement'))
      },
      {
        path: '/driver-management',
        exact: true,
        component: lazy(() => import('views/DriverManagement'))
      },
      {
        path: '/assistant-management',
        exact: true,
        component: lazy(() => import('views/AssistantManagement'))
      },
      {
        path: '/truck-report',
        exact: true,
        component: lazy(() => import('views/TruckReport'))
      },
      {
        path: '/fuel-surcharge',
        exact: true,
        component: lazy(() => import('views/FuelSurcharge'))
      },
      {
        path: '/quota-km',
        exact: true,
        component: lazy(() => import('views/QuotaKM'))
      },
      {
        path: '/schedule',
        exact: true,
        component: lazy(() => import('views/Schedule'))
      },
      {
        path: '/driver-list',
        exact: true,
        component: lazy(() => import('views/DriverList'))
      },
      {
        path: '/excel-order',
        exact: true,
        component: lazy(() => import('views/ExcelOrder'))
      },
      {
        path: '/salary-management',
        exact: true,
        component: lazy(() => import('views/SalaryManagement'))
      },
      {
        path: '/company-price-management',
        exact: true,
        component: lazy(() => import('views/CompanyPriceManagement'))
      },
      {
        path: '/general-report',
        exact: true,
        component: lazy(() => import('views/GeneralReport'))
      },
      {
        path: '/monthly-report',
        exact: true,
        component: lazy(() => import('views/MonthlyReport'))
      },
      {
        path: '/revenue-report',
        exact: true,
        component: lazy(() => import('views/RevenueReport'))
      },
      {
        path: '/debt-report',
        exact: true,
        component: lazy(() => import('views/DebtReport'))
      },
      {
        path: '/notice-management',
        exact: true,
        component: lazy(() => import('views/NoticeManagement'))
      },
      {
        path: '/change-password',
        exact: true,
        component: lazy(() => import('views/ChangePassword'))
      },

      //end
      {
        path: '/getting-started',
        exact: true,
        component: lazy(() => import('views/GettingStarted'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
