import { revenueReportApi, getPlatesApi, getCustomersApi } from 'apis';
const prefix = 'revenueReport';

export const SET_FILTER_DATA = `${prefix}_SET_FILTER_DATA`;
export const GET_DASHBOARD_REPORT = `${prefix}_GET_DASHBOARD_REPORT`;
export const GET_FILTER_DATA = `${prefix}_GET_FILTER_DATA`;
export const SET_LOADING = `${prefix}_SET_LOADING`;

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    filter
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getDashboardReport = data => async dispatch => {
  dispatch(setLoading(true));
  try {
    const res = await revenueReportApi(data);
    const {
      doanh_thu_toan_bo,
      toan_bo_chi_phi,
      tong_lai_toan_bo,
      list_don,
      table_data_key_name,
      bien_so_xe_table_data,
      chart_data
    } = res;
    const dashboard = {
      doanh_thu: [],
      tong_chi_phi: [],
      tong_lai: [],
      labels: []
    };
    chart_data.forEach(data => {
      dashboard.doanh_thu.push(data.doanh_thu);
      dashboard.tong_chi_phi.push(data.tong_chi_phi);
      dashboard.tong_lai.push(data.tong_lai);
      dashboard.labels.push(data.month);
    });
    dispatch({
      type: GET_DASHBOARD_REPORT,
      dashboard,
      doanh_thu_toan_bo,
      toan_bo_chi_phi,
      tong_lai_toan_bo,
      list_don,
      table_data_key_name,
      bien_so_xe_table_data
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getFilterData = () => async dispatch => {
  const plates = await getPlatesApi();
  const customers = await getCustomersApi();
  dispatch({
    type: GET_FILTER_DATA,
    plates,
    customers
  });
};
