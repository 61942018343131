export const UPDATE_WAY_POINTS = 'UPDATE_WAY_POINTS';
export const UPDATE_MARKER = 'UPDATE_MARKER';
export const SET_VIDEOS = 'SET_VIDEOS';

export const updateVideoPlayBackWayPoints = wayPoints => dispatch =>
  dispatch({
    type: UPDATE_WAY_POINTS,
    wayPoints
  });

export const updateVideoPlayBackMarker = marker => dispatch =>
  dispatch({
    type: UPDATE_MARKER,
    marker
  });

export const setVideosPlayBack = videos => dispatch =>
  dispatch({
    type: SET_VIDEOS,
    videos
  });
