import * as actionTypes from 'actions/assistantManagementActions';

const initialState = {
  assistants: [],
  loading: false,
  selectedAssistant: [],
  formState: { open: false, edit: false }
};

const assistantManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ASSISTANT: {
      return {
        ...state,
        assistants: action.assistants,
        loading: false,
        selectedAssistant: []
      };
    }

    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }

    case actionTypes.SET_SELECTED_ASSISTANT: {
      return {
        ...state,
        selectedAssistant: action.selected
      };
    }

    case actionTypes.UPDATE_FORM: {
      return {
        ...state,
        formState: { ...state.formState, ...action.formState }
      };
    }

    default: {
      return state;
    }
  }
};

export default assistantManagementReducer;
