import { getTrashsApi, getExcelDeletedApi } from 'apis';

const prefix = 'trashManagement';
export const SET_TRASH = `${prefix}SET_TRASH`;
export const SET_EXCEL = `${prefix}SET_EXCEL`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_EXCEL_LOADING = `${prefix}SET_EXCEL_LOADING`;
export const SET_SELECTED_TRASH = `${prefix}SET_SELECTED_TRASH`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_TRASH`;
export const SET_FILTER_DATA = `${prefix}SET_FILTER_DATA_TRASH`;

export const setTrashs = trashs => dispatch => {
  dispatch({
    type: SET_TRASH,
    trashs
  });
};

export const setExcels = excels => dispatch => {
  dispatch({
    type: SET_EXCEL,
    excels
  });
};

export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setExcelLoading = excelLoading => dispatch => {
  dispatch({
    type: SET_EXCEL_LOADING,
    excelLoading
  });
};

export const getTrashs = (startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const trashs = await getTrashsApi(startDate, endDate);
    dispatch(setTrashs(trashs));
  } catch (error) {
    console.log('get trashs error ', error);
    dispatch(setLoading(false));
  }
};
export const getExcels = (startDate, endDate) => async dispatch => {
  dispatch(setExcelLoading(true));
  try {
    const excels = await getExcelDeletedApi(startDate, endDate);
    dispatch(setExcels(excels));
  } catch (error) {
    console.log('get excels error ', error);
    dispatch(setExcelLoading(false));
  }
};

export const setSelectedTrash = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_TRASH,
    selected
  });
};
