import * as actionTypes from 'actions';

const initialState = {
  images: []
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IMAGES: {
      return {
        ...state,
        images: action.images
      };
    }
    default: {
      return state;
    }
  }
};

export default imageReducer;
