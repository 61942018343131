import { getExcelsApi } from 'apis';

const prefix = 'excelManagement';
export const SET_EXCEL = `${prefix}SET_EXCEL`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_EXCEL = `${prefix}SET_SELECTED_EXCEL`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_EXCEL`;
export const SET_FILTER_DATA = `${prefix}SET_FILTER_DATA_EXCEL`;

export const setExcels = excels => dispatch => {
  dispatch({
    type: SET_EXCEL,
    excels
  });
};
export const setFilter = data => dispatch => {
  dispatch({
    type: SET_FILTER_DATA,
    data
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getExcels = (startDate, endDate) => async dispatch => {
  dispatch(setLoading(true));
  try {
    const excels = await getExcelsApi(startDate, endDate);
    dispatch(setExcels(excels));
  } catch (error) {
    console.log('get excels error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedExcel = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_EXCEL,
    selected
  });
};
