import { listTruckApi, deleteTrucksApi } from 'apis';

export const SET_TRUCKS = 'SET_TRUCKS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_TRUCK = 'SET_SELECTED_TRUCK';
export const OPEN_FORM_ADD_TRUCK = 'OPEN_FORM_ADD_TRUCK';
export const DELETE_TRUCK = 'DELETE_TRUCK';

export const getTrucks = () => async dispatch => {
  try {
    dispatch(setLoading(true));
    const trucks = await listTruckApi();
    dispatch({
      type: SET_TRUCKS,
      trucks
    });
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const setSelectedTruck = selectedTrucks => dispatch => {
  dispatch({
    type: SET_SELECTED_TRUCK,
    selectedTrucks
  });
};

export const openFormAddTruck = (open, truck) => dispatch => {
  dispatch({
    type: OPEN_FORM_ADD_TRUCK,
    open,
    truck
  });
};

export const deleteTruck = trucks => async dispatch => {
  try {
    await deleteTrucksApi(trucks);
    dispatch({
      type: DELETE_TRUCK,
      trucks
    });
  } catch (error) {
    console.log('Delete truck', error);
  }
};
