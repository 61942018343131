import { listMarkerApi } from 'apis';

export const SET_MARKER_LIST = 'SET_MARKER_LIST';
export const SET_SELECTED_MARKERS = 'SET_SELECTED_MARKERS';
export const SET_SHOW_MARKERS = 'SET_SHOW_MARKERS';
export const SET_LOADING = 'SET_LOADING';
export const REMOVE_MARKER = 'REMOVE_MARKER';
export const SET_CONFIRM_FORM = 'SET_CONFIRM_FORM';
export const SET_UPLOAD_FORM = 'SET_UPLOAD_FORM';
export const SET_ADD_POINT = 'SET_ADD_POINT';

const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

const setMarkers = markers => dispatch => {
  dispatch({
    type: SET_MARKER_LIST,
    markers
  });
};

export const removeMarkers = removed => dispatch => {
  dispatch({
    type: REMOVE_MARKER,
    removed
  });
};

export const getMarkers = () => async dispatch => {
  try {
    dispatch(setLoading(true));
    const markers = await listMarkerApi();
    dispatch(setMarkers(markers));
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const setSelectedMarkers = selectedMarkers => dispatch => {
  dispatch({
    type: SET_SELECTED_MARKERS,
    selectedMarkers
  });
};

export const setShowMarkers = showMarkers => dispatch => {
  dispatch({
    type: SET_SHOW_MARKERS,
    showMarkers
  });
};

export const setConfirmForm = confirmForm => dispatch => {
  dispatch({
    type: SET_CONFIRM_FORM,
    confirmForm
  });
};

export const setUploadForm = uploadForm => dispatch => {
  dispatch({
    type: SET_UPLOAD_FORM,
    uploadForm
  });
};

export const setAddPoint = addNewPoint => dispatch => {
  dispatch({
    type: SET_ADD_POINT,
    addNewPoint
  });
};
