import { getAssistantsApi } from 'apis';

export const SET_ASSISTANT = 'SET_ASSISTANT';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_ASSISTANT = 'SET_SELECTED_ASSISTANT';
export const UPDATE_FORM = 'UPDATE_FORM_ ASSISTANT';

export const setAssistants = assistants => dispatch => {
  dispatch({
    type: SET_ASSISTANT,
    assistants
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getAssistants = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const assistants = await getAssistantsApi();
    dispatch(setAssistants(assistants));
  } catch (error) {
    console.log('get assistants error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedAssistant = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_ASSISTANT,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
