import { driverSortListApi } from 'apis';

const prefix = 'driverList';

export const SET_VEHICLE = `${prefix}SET_VEHICLE`;
export const SET_SORT_TYPE = `${prefix}SET_SORT_TYPE`;
export const SET_LOADING = `${prefix}SET_LOADING`;
export const SET_SELECTED_VEHICLE = `${prefix}SET_SELECTED_VEHICLE`;
export const UPDATE_FORM = `${prefix}UPDATE_FORM_VEHICLE`;

export const setDrivers = vehicles => dispatch => {
  dispatch({
    type: SET_VEHICLE,
    vehicles
  });
};

export const setSortType = sortType => dispatch => {
  dispatch({
    type: SET_SORT_TYPE,
    sortType
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getDriverList = data => async dispatch => {
  dispatch(setLoading(true));
  try {
    const drivers = await driverSortListApi(data);
    console.log(data);
    console.log(drivers);
    dispatch(setDrivers(drivers));
  } catch (error) {
    console.log('get drivers error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedVehicle = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_VEHICLE,
    selected
  });
};

export const updateForm = formState => dispatch => {
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};
