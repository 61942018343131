import * as actionTypes from 'actions';

const initialState = {
  truckLoading: false,
  trucks: []
};

const cameraPlaybackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRUCK_LIST: {
      return {
        ...state,
        trucks: action.trucks
      };
    }

    default: {
      return state;
    }
  }
};

export default cameraPlaybackReducer;
