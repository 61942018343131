import { getUsersApi } from 'apis';

export const SET_DRIVER = 'SET_DRIVER';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_DRIVER = 'SET_SELECTED_DRIVER';
export const UPDATE_FORM = 'UPDATE_FORM_DRIVER';
export const SET_FILTER = 'SET_FILTER_DRIVER';

export const setDrivers = drivers => dispatch => {
  dispatch({
    type: SET_DRIVER,
    drivers
  });
};

export const setLoading = loading => dispatch => {
  dispatch({
    type: SET_LOADING,
    loading
  });
};

export const getDrivers = () => async dispatch => {
  dispatch(setLoading(true));
  try {
    const drivers = await getUsersApi();
    dispatch(setDrivers(drivers));
  } catch (error) {
    console.log('get Drivers error ', error);
    dispatch(setLoading(false));
  }
};

export const setSelectedDriver = selected => dispatch => {
  dispatch({
    type: SET_SELECTED_DRIVER,
    selected
  });
};

export const updateForm = formState => dispatch => {
  if (formState.open === false) dispatch(getDrivers());
  dispatch({
    type: UPDATE_FORM,
    formState
  });
};

export const setFilter = filter => dispatch => {
  dispatch({
    type: SET_FILTER,
    filter
  });
};
