import { request } from 'utils/axios';
import moment from 'moment';

function handleError(message, error) {
  if (error.response) {
    console.log(message, error.response.data);
    return error.response.data;
  }
  console.log(message, error);
  return { status: 500, message: '' };
}

export async function loginApi(userName, password) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/user/login',
      data: {
        username: userName,
        password: password
      }
    });
    return response;
  } catch (error) {
    return handleError('login', error);
  }
}

export async function request_demo(data) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/form/requeset_demo',
      data: data
    });
    return response;
  } catch (error) {
    return handleError('request_demo', error);
  }
}

export async function confirmTokenApi() {
  const response = await request.request({
    method: 'GET',
    url: '/user/validate'
  });
  return response;
}

export async function getUserInfo(userName) {
  const response = await request.request({
    method: 'POST',
    url: '/user/get_info',
    data: {
      username: userName
    }
  });
  return response.data;
}

export async function currentListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_current_list'
    });
    return response;
  } catch (error) {
    return handleError('currentListApi', error);
  }
}

export async function driverListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver/driver_list'
    });
    return response;
  } catch (error) {
    return handleError('driverListApi', error);
  }
}

export async function driverReportApi(driverName, start, end) {
  const name =
    driverName || driverName !== '' ? { driver_name: driverName } : {};

  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_list_by_date',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        ...name
      }
    });
    return response.data;
  } catch (error) {
    return handleError('driverReportApi', error);
  }
}

export async function driverRouteApi(driverName, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/get_driver_route_report',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        driver_name: driverName
      }
    });
    return response.data;
  } catch (error) {
    return handleError('driverRouteApi', error);
  }
}

export async function abnormalActivityApi(driverName, start, end) {
  const name =
    driverName || driverName !== '' ? { driver_name: driverName } : {};

  try {
    const response = await request.request({
      method: 'POST',
      url: '/driver_report/abnormal_activity',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        ...name
      }
    });
    return response.data;
  } catch (error) {
    return handleError('abnormalActivityApi', error);
  }
}

export async function listExcelApi(start, end) {
  let time = {};

  if (start || start !== '')
    time = {
      start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(end).format('YYYY-MM-DD HH:mm:ss')
    };

  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/get_list',
      data: {
        ...time
      }
    });
    return response.data;
  } catch (error) {
    return handleError('listExcelApi', error);
  }
}

export async function openFileExcelApi(fileId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/open_file',
      data: {
        file_id: fileId
      }
    });
    return response.data;
  } catch (error) {
    return handleError('openFileExcelApi', error);
  }
}

export async function openContractInfoApi(contractId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_info',
      data: {
        contract_id: contractId
      }
    });
    return response.data;
  } catch (error) {
    return handleError('openOrderInfoApi', error);
  }
}

export async function deleteContractInfoApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/delete',
      data: {
        list_contract: ids
      }
    });
    return response.data;
  } catch (error) {
    return handleError('deleteContractInfoApi', error);
  }
}

export async function listStopAndVideoApi(contractId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_list_stop_and_video',
      data: {
        contract_id: contractId
      }
    });
    return response.data;
  } catch (error) {
    return handleError('listStopAndVideoApi', error);
  }
}

export async function listMarkerApi() {
  const response = await request.request({
    method: 'POST',
    url: '/marker/get_all_list'
  });
  return response.data;
}

export function uploadFileApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('file', file);

    return request.post('/excel/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadFile', error);
  }
}

export async function downloadFileApi(fileId) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/download',
      data: {
        file_id: fileId
      }
    });
    return response.data;
  } catch (error) {
    return handleError('downloadFile', error);
  }
}

export async function deleteFileApi(files) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/excel/delete',
      data: {
        files
      }
    });
    return response.data;
  } catch (error) {
    return handleError('deleteFile', error);
  }
}

export async function currentTruckListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/get_current_truck_list'
    });
    return response.data;
  } catch (error) {
    return handleError('currentTruckListApi', error);
  }
}

export async function listVideoBusinessApi(truckPlate, start, end, camNumber) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/etracking/get_list_by_date',
      data: {
        truck_plate: truckPlate,
        start_date: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        cam_number: camNumber
      }
    });
    return response.data;
  } catch (error) {
    return handleError('listVideoBusinessApi', error);
  }
}

export async function truckListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/truck_list'
    });
    return response.data;
  } catch (error) {
    return handleError('truckListApi', error);
  }
}

export async function routeFromVideoApi(plate, start) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/truck/get_route_from_video',
      data: {
        plate,
        start_time: start
      }
    });
    return response.data;
  } catch (error) {
    return handleError('routeFromVideoApi', error);
  }
}

export async function addPoint(data) {
  const response = await request.request({
    method: 'POST',
    url: '/marker/create',
    data: data
  });
  return response;
}

export async function removePointApi(listPoint) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/marker/delete',
      data: {
        list_coor: listPoint
      }
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return handleError('removePointApi', error.response);
  }
}

export function uploadPointFileApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/import_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}
export function uploadWalletFileApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/wallet/update_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadWalletFileApi', error);
  }
}

export function uploadPhuThuExcel(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post(
      '/tms/phu_thu_nhien_lieu/update_phu_thu_excel',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      }
    );
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export function uploadDinhMucKMExcel(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/dinh_muc_km/update_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export function uploadDinhMucExcel(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/dinh_muc/update_dinh_muc_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export function uploadSalaryExcel(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post(
      '/tms/driver_salary/update_excel/driver_salary',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      }
    );
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export function uploadExcelApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export function uploadExcelPriceApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_excel_chi_phi', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadExcelPriceApi', error);
  }
}

export function uploadExcelGasApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_excel_xang_dau', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadExcelGasApi', error);
  }
}

export function uploadExcelKMDieuDo(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_excel_km_dieu_do', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadExcelGasApi', error);
  }
}

export function uploadExcelDauTonAPI(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_dau_ton', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadExcelGasApi', error);
  }
}

export function uploadDateTimeApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/chuyen_di/update_excel_ngay_gio', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadDateTimeApi', error);
  }
}

export async function updateContractApi(dataUpdate) {
  try {
    const response = await request.request({
      method: 'PUT',
      url: '/contract/update',
      data: dataUpdate
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
    return handleError('updateContractApi', error.response);
  }
}

export async function routeDataApi(plate, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/contract/get_route_data',
      data: {
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss'),
        plate
      }
    });
    return response.data;
  } catch (error) {
    return handleError('routeDataApi', error);
  }
}

export async function imageListApi(plate, start, end) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/image/get_list',
      data: {
        truck_plate: plate,
        start_time: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        end_time: moment(end).format('YYYY-MM-DD HH:mm:ss')
      }
    });
    return response.data;
  } catch (error) {
    return handleError('imageListApi', error);
  }
}

export async function addContact(data) {
  const response = await request.request({
    method: 'POST',
    url: '/contract/create',
    data
  });
  return response.data;
}

export async function listTruckApi() {
  const response = await request.request({
    method: 'POST',
    url: '/truck/get_list'
  });
  return response.data;
}

export async function addTruckApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/truck/create',
    data
  });
  return response.data;
}

export async function updateTrucksApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/truck/update',
    data
  });
  return response.data;
}

export async function deleteTrucksApi(listVid) {
  const response = await request.request({
    method: 'POST',
    url: '/truck/delete',
    data: {
      list_vid: listVid
    }
  });
  return response.data;
}

export async function listWarningApi() {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/list_canh_bao',
    data: {
      username: 'driver_demo'
    }
  });
  return response.data;
}

export async function addWarningApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/tao_canh_bao',
    data
  });
  return response.data;
}

export async function updateWarningApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/cap_nhat',
    data
  });
  return response.data;
}

export async function deleteWarningApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/canh_bao/xoa_canh_bao',
    data: {
      id_canh_bao: ids
    }
  });
  return response.data;
}

export async function listRuleApi(plate) {
  const data = plate && plate !== '' ? { plate } : {};
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/get_rules',
    data
  });
  return response.data;
}

export async function addRuleApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/add_rule',
    data
  });
  return response.data;
}

export async function updateRuleApi(data) {
  if (data.total_km && typeof data.total_km == 'string')
    data.total_km = Number(data.total_km);
  const response = await request.request({
    method: 'PUT',
    url: '/truck_info/edit_rule',
    data
  });
  return response.data;
}

export async function deleteRuleApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/delete_rules',
    data: {
      data: ids
    }
  });
  return response.data;
}

export async function listPaperApi(plate) {
  const data = plate && plate !== '' ? { plate } : {};
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/get_list_paper',
    data
  });
  return response.data;
}

const createFormData = (files, body = {}) => {
  const data = new FormData();

  files.forEach(photo => {
    data.append('files', photo);
  });

  Object.keys(body).forEach(key => {
    data.append(key, body[key]);
  });
  return data;
};

export async function addPaperApi(files, body, onUploadProgress) {
  try {
    const formData = createFormData(files, body);

    return request.post('/truck_info/add_paper', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('addPaperApi', error);
  }
}

export async function deletePaperApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/truck_info/delete_paper',
    data: {
      data: ids
    }
  });
  return response.data;
}

// start
export async function getTripsApi(status, startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/list_web',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }
  });
  return response.data;
}

export async function getOrdersApi(status, startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_hang/list',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }
  });
  return response.data;
}

export async function getTripInfoApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/get_info',
    data: {
      id_chuyen_di: id
    }
  });
  return response.data;
}

export async function createTripApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/create',
    data
  });
  return response.data;
}

export async function updateTripApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/update',
    data
  });
  return response.data;
}

export async function deleteTripApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chuyen_di/delete',
      data: {
        list_id: ids
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function printTripApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/excel/get_dieu_xe_file',
      data: {
        list_id: ids
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


export async function LockDataTripApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chuyen_di_info/add_lock_trip',
      data: {
        list_id: ids
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


export async function UnLockDataTripApi(ids) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chuyen_di_info/remove_lock_trip',
      data: {
        list_id: ids
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


export async function getSuggestApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/suggestion/form'
  });
  return response.data;
}

export async function getSuggestOrderSSApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/suggestion'
  });
  return response.data;
}

export function uploadTripImageApi(files, onUploadProgress) {
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    return request.post('/tms/don_hang/upload_image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadTripImageApi', error);
  }
}

export async function endTripApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/ket_thuc',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function resetTripApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/gps_trip_action/re_calculate_trip',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function cancelTripApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/gps_trip_action/cancel_current_trip',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function updatePayedApi(id, type, note, receiverName) {
  const response = await request.request({
    method: 'POST',
    url: `/tms/don_hang/update_${type}`,
    data: {
      id,
      nguoi_thu_tien: receiverName,
      ghi_chu: note
    }
  });
  return response.data;
}

export async function updateUnPayApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_hang/update_chua_thanh_toan',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getRoutesApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tuyen_duong/list'
  });
  return response.data;
}

export async function createOrderSSApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/create',
    data
  });
  return response.data;
}

export async function updateOrderSSApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/update',
    data
  });
  return response.data;
}

export async function getOrdersSSApi(status, startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/list',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }
  });
  return response.data;
}

export async function remoteOrderSSAPi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getOrderSSInfoApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/don_chuyen_tram/get_info',
    data: {
      id_chuyen_di: id
    }
  });
  return response.data;
}

export async function createRouteApi(route, inputPlate, outputPlate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tuyen_duong/create',
    data: {
      ten_tuyen_duong: route,
      noi_lay_hang: inputPlate,
      noi_tra_hang: outputPlate
    }
  });
  return response.data;
}

export async function updateRouteApi(id, route, inputPlate, outputPlate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tuyen_duong/update',
    data: {
      id_tuyen_duong: id,
      ten_tuyen_duong: route,
      noi_lay_hang: inputPlate,
      noi_tra_hang: outputPlate
    }
  });
  return response.data;
}

export async function deleteRouteApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tuyen_duong/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getVehiclesApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/list',
    data
  });
  return response.data;
}

export async function getDriverListApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/list',
    data
  });
  return response.data;
}

export async function createDriverApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/create',
    data
  });
  return response.data;
}

export async function updateDriverApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/update',
    data
  });
  return response.data;
}

export async function deleteDriverApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/delete',
    data
  });
  return response.data;
}

export async function createVehicleApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/create',
    data
  });
  return response.data;
}

export async function updateVehicleApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/update',
    data
  });
  return response.data;
}

export async function deleteVehicleApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getUsersApi() {
  const response = await request.request({
    method: 'POST',
    url: '/user/get_list'
  });
  return response.data;
}
export async function getUsersFullNameApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/get_list_fullname'
  });
  return response.data;
}

export async function createUserApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/user/create',
    data
  });
  return response.data;
}

export async function updateUserApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/user/update_info',
    data
  });
  return response.data;
}
export async function resetPasswordApi(data) {
  const response = await request.request({
    method: 'PUT',
    url: '/user/reset_password',
    data
  });
  return response.data;
}

export async function deleteUserApi(username) {
  const response = await request.request({
    method: 'DELETE',
    url: '/user/delete',
    data: {
      username
    }
  });
  return response.data;
}
export async function getAssistantsApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/thau_phu/list'
  });
  return response.data;
}

export async function createAssistantApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/thau_phu/create',
    data
  });
  return response.data;
}

export async function updateAssistantApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/thau_phu/update',
    data
  });
  return response.data;
}

export async function deleteAssistantApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/thau_phu/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getCompanysApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cong_ty/list'
  });
  return response.data;
}

export async function createCompanyApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cong_ty/create',
    data
  });
  return response.data;
}

export async function updateCompanyApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cong_ty/update',
    data
  });
  return response.data;
}

export async function deleteCompanyApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cong_ty/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function scheduleApi(year) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/lich_trinh/calendar',
    data: { year }
  });
  return response.data;
}

export async function allReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/toan_bo',
    data
  });
  return response.data;
}

export async function generalReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/tong_hop',
    data
  });
  return response.data;
}

export async function revenueReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/doanh_thu',
    data
  });
  return response.data;
}

export async function debtReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/cong_no',
    data
  });
  return response.data;
}

export async function getPlatesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/bao_cao/list_bsx'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getCustomersApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/bao_cao/list_khach_hang'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getNoticesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/notification/list'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getCompaniesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/list/ten_cong_ty'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getListCustomerApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/list/khach_hang'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function getFilterOrdersApi(
  type,
  listFilter,
  startTime,
  endTime,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/list/loc_don_hang',
    data: {
      type,
      list_filter: listFilter,
      start_time: startTime,
      end_time: endTime,
      list_form
    }
  });
  return response.data;
}

export async function getFilterOrdersApiTaiExcel(
  type,
  listFilter,
  startTime,
  endTime,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/list/loc_don_hang_tai_excel',
    data: {
      type,
      list_filter: listFilter,
      start_time: startTime,
      end_time: endTime,
      list_form
    }
  });
  return response.data;
}

export async function getFilterApiTaiExcelVECTTRuck(
  type,
  listFilter,
  startTime,
  endTime,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/list/loc_don_hang_download_vect_truck_report',
    data: {
      type,
      list_filter: listFilter,
      start_time: startTime,
      end_time: endTime,
      list_form
    }
  });
  return response.data;
}

export async function downloadExcelApi(
  status,
  startDate,
  endDate,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/excel_download',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      list_form
    }
  });
  return response.data;
}

export async function downloadExcelApiVECTTruck(
  status,
  startDate,
  endDate,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di/vetc_truck_report_download',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      list_form
    }
  });
  return response.data;
}

export async function downloadAnhKhachHang(
  type,
  list_filter,
  startDate,
  endDate
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/list/download_anh',
    data: {
      type: type,
      list_filter,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      list_form: 'string'
    }
  });
  return response.data;
}

export async function downloadExcelPhuThu() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phu_thu_nhien_lieu/download_excel_phu_thu',
    data: {}
  });
  return response.data;
}

export async function downloadExcelDinhMucKM() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dinh_muc_km/download_excel',
    data: {}
  });
  return response.data;
}

export async function downloadExcelDinhMuc() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dinh_muc/download_excel_dinh_muc',
    data: {}
  });
  return response.data;
}

export async function downloadExcelDriverList() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/excel_download',
    data: {}
  });
  return response.data;
}
export async function downloadExcelSalary(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_salary/excel_download',
    data
  });
  return response.data;
}

export async function QuickTripApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tool/quick_create',
    data: {
      list_don_hang: ids
    }
  });
  return response.data;
}

export async function getExcelsApi(startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/list_file',
    data: {
      start_time: moment(startDate).format('YYYY-MM-DD'),
      end_time: moment(endDate).format('YYYY-MM-DD')
    }
  });
  return response.data;
}

export async function deleteExcelsApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getTrashsApi(startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bin/list_chuyen_di',
    data: {
      start_time: moment(startDate).format('YYYY-MM-DD'),
      end_time: moment(endDate).format('YYYY-MM-DD')
    }
  });
  return response.data;
}

export async function getExcelDeletedApi(startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bin/list_excel',
    data: {
      start_time: moment(startDate).format('YYYY-MM-DD'),
      end_time: moment(endDate).format('YYYY-MM-DD')
    }
  });
  return response.data;
}

export async function restoreExcelsApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bin/restore',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getNoticeNumberApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tool/get_noti_tag_number'
  });
  return response.data;
}

export async function updateLastNoticeDate() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tool/update_last_noti_date'
  });
  return response.data;
}

export async function formCreateTripApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/form_chi_phi/chuyen_di_create'
  });
  return response.data;
}

export async function expensesListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/form_chi_phi/list'
  });
  return response.data;
}

export async function expensesCreateApi(keys) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/form_chi_phi/create',
    data: {
      list: keys
    }
  });
  return response.data;
}

export async function expensesDeleteApi(keys) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/form_chi_phi/delete',
    data: {
      list: keys
    }
  });
  return response.data;
}

export async function tripCreateFormCostApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/form_chi_phi/chuyen_di_create'
  });
  return response.data;
}

export async function getOrdersWaitingApproveApi(status, startDate, endDate) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/get_list',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }
  });
  return response.data;
}

export async function getOrdersWaitingApproveImportApi(
  status,
  startDate,
  endDate
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/get_list_import',
    data: {
      status,
      start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')
    }
  });
  return response.data;
}

export async function actionOrderWaitingApi(ids, action) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/action',
    data: {
      list_id: ids,
      action
    }
  });
  return response.data;
}

export async function deleteOrderWaitingApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/delete_don_hang',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function quickCreateWithOrderWaitingApproveApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/quick_create',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function getTripTempInfo(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/get_info_chuyen_di_temp',
    data: {
      id_chuyen_di: id
    }
  });
  return response.data;
}

export async function createWithOrderWaitingApproveApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/add_don_hang',
    data: {
      list_don_hang: data
    }
  });
  return response.data;
}

export async function updateWithOrderWaitingApproveApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/update_don_hang',
    data
  });
  return response.data;
}

export async function getOrderWaitingApproveApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/get_info_don_hang',
    data: {
      id_don_hang: id
    }
  });
  return response.data;
}

export async function createTripTempApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/create_new_chuyen_di',
    data
  });
  return response.data;
}

export async function listUserApi() {
  const response = await request.request({
    method: 'POST',
    url: '/user/get_list'
  });
  return response.data;
}

export async function walletReportApi(user_id, ngay_thang) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/wallet/bao_cao',
    data: {
      user_id,
      ngay_thang
    }
  });
  return response.data;
}

export async function walletAddApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/wallet/add_tien',
    data
  });
  return response.data;
}
export async function walletUpdateApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/wallet/update_tien',
    data
  });
  return response.data;
}

export async function walletDeleteApi(list_id) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/wallet/delete',
      data: {
        list_id
      }
    });
    return response.data;
  } catch (error) {
    return handleError('walletDeleteApi', error);
  }
}

export async function formTripList() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/get_form_chuyen_di_list'
  });
  return response.data;
}

export async function formOrderList() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/get_form_don_hang_list'
  });
  return response.data;
}

export function toolUploadFileApi(files, onUploadProgress) {
  try {
    let formData = new FormData();

    files.forEach(file => {
      formData.append('files', file);
    });

    return request.post('/tms/tool/upload_files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('toolUploadFileApi', error);
  }
}

export async function checkFreeTruckApi(date) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/tool/check_free_truck',
      data: { date }
    });
    return response.data;
  } catch (error) {
    console.log('checkFreeTruckApi error', error);
    return { list: [] };
  }
}

export async function bsxApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/list_bsx'
  });
  return response.data;
}

export async function oilPricesApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/gia_dau/list'
  });
  return response.data;
}

export async function oilPriceUpdateApi(oilPrice) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/gia_dau/update',
    data: {
      gia_dau: oilPrice
    }
  });
  return response.data;
}

export async function fixedExpensesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chi_phi_co_dinh/list'
    });
    return response.data;
  } catch (error) {
    console.log('fixedExpensesApi error', error);
    return [];
  }
}

export async function createFixedExpensesApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chi_phi_co_dinh/create',
    data
  });
  return response.data;
}

export async function updateFixedExpensesApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chi_phi_co_dinh/update',
    data
  });
  return response.data;
}

export async function formTripListChiPhi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/get_list_chi_phi_update'
  });
  return response.data;
}

export function uploadExcelChiPhiFilterAPI(file, listForm, onUploadProgress) {
  try {
    let formData = new FormData();
    formData.append('text', listForm);
    formData.append('uploaded_file', file);
    return request.post('/tms/chuyen_di/chi_phi_custom', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadExcelChiPhiFilterAPI', error);
  }
}

export function uploadExcelAdditionalDataAPI(file, listForm, onUploadProgress) {
  try {
    let formData = new FormData();
    formData.append('text', listForm);
    formData.append('uploaded_file', file);
    return request.post(
      '/tms/chuyen_di_info/update_excel/don_hang_additional_data',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      }
    );
  } catch (error) {
    return handleError('uploadExcelAdditionalDataAPI', error);
  }
}

export async function deleteFixedExpensesApi(ids) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chi_phi_co_dinh/delete',
    data: {
      list_id: ids
    }
  });
  return response.data;
}

export async function chooseFixedExpensesApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chi_phi_co_dinh/list_chon'
    });
    return response.data;
  } catch (error) {
    console.log('chooseFixedExpensesApi  error', error);
    return [];
  }
}

export async function fixedExpensesPlateApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/chi_phi_co_dinh/list_chon_bien_so'
    });
    return response.data;
  } catch (error) {
    console.log('fixedExpensesPlateApi  error', error);
    return [];
  }
}

export async function checkInsert(ids, id_chuyen_di) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/booking/check_insert',
    data: {
      list_don_hang: ids,
      id_chuyen_di
    }
  });
  return response.data;
}

export async function checkDataStatus() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cached_process/check_data_status'
  });
  return response.data;
}

export async function monthlyGeneralReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cached_process/bao_cao_tong_hop',
    data
  });
  return response.data;
}

export async function monthlyReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cached_process/bao_cao_theo_thang',
    data
  });
  return response.data;
}

export async function downloadMonthlyReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cached_process/download_excel_bao_cao',
    data
  });
  return response.data;
}

export async function downloadexcelMonthlyReportApi(
  startDate,
  endDate,
  list_form = []
) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/cached_process/download_excel_month',
    data: {
      start_time: moment(startDate).format('YYYY-MM'),
      end_time: moment(endDate).format('YYYY-MM'),
      list_form
    }
  });
  return response.data;
}

export async function areaListApi(id) {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/dia_diem/list',
      data: {
        id_cong_ty: id
      }
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function deleteAreaApi(ids, id_cong_ty) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/delete',
    data: {
      list_id: ids,
      id_cong_ty
    }
  });
  return response.data;
}

export async function updateAreaApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/update',
    data
  });
  return response.data;
}

export async function createAreaApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/create',
    data
  });
  return response.data;
}

export async function companyListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/cong_ty/list'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function fuelListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/phu_thu_nhien_lieu/list'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function deleteFuelApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phu_thu_nhien_lieu/delete',
    data: { id }
  });
  return response.data;
}

export async function createFuelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phu_thu_nhien_lieu/create',
    data
  });
  return response.data;
}

export async function updateFuelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phu_thu_nhien_lieu/update',
    data
  });
  return response.data;
}

export async function quotaListApi() {
  try {
    const response = await request.request({
      method: 'POST',
      url: '/tms/dinh_muc_km/list'
    });
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function deleteQuotaApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dinh_muc_km/delete',
    data: { id }
  });
  return response.data;
}

export async function createQuotaApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dinh_muc_km/create',
    data
  });
  return response.data;
}

export async function updateQuotaApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dinh_muc_km/update',
    data
  });
  return response.data;
}

export async function dataFormFuelApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/list/list_nha_kho_phan_phoi'
  });
  return response.data;
}

export async function getDataCustomerUser(name) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/doi_tac/list_doi_tac',
    data: {
      ten_khach_gui: name
    }
  });
  return response.data;
}

export async function createCustomerUser(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/doi_tac/create',
    data
  });
  return response.data;
}

export async function updateCustomerUser(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/doi_tac/update',
    data
  });
  return response.data;
}

export async function deleteCustomerUser(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/doi_tac/delete',
    data
  });
  return response.data;
}

export async function changePasswordCustomerUser(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/doi_tac/reset_password',
    data
  });
  return response.data;
}

export async function getListNhaKho(id_chuyen_di) {
  const response = await request.request({
    method: 'POST',
    url: '/gps_trip_action/get_list_nha_kho',
    data: {
      id_chuyen_di
    }
  });
  return response.data;
}

export async function updateKhoChiDinh(data) {
  const response = await request.request({
    method: 'POST',
    url: '/gps_trip_action/add_kho_chi_dinh',
    data
  });
  return response.data;
}

export async function getKhoChiDinh(ten_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: 'tms/dia_diem/get_list_nha_kho',
    data: {
      ten_khach_gui
    }
  });
  return response.data;
}

export async function viewTrip(id_chuyen_di) {
  const response = await request.request({
    method: 'POST',
    url: 'gps_trip_action/view_lich_trinh',
    data: {
      id_chuyen_di
    }
  });
  return response.data;
}

export async function downloadDiaDiemApi(ten_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/download_excel',
    data: { ten_khach_gui }
  });
  return response.data;
}

export function uploadFileDiaDiemApi(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('file', file);

    return request.post('/tms/dia_diem/excel_update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadFile', error);
  }
}

export async function getListKhoPhanPhoiApi(ten_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/get_list_kho_pp',
    data: { ten_khach_gui }
  });
  return response.data;
}

export async function getListPhanPhoiApi(ten_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/get_list_phan_phoi',
    data: { ten_khach_gui }
  });
  return response.data;
}

export async function getDiaDiemKhoCD(ten_khach_gui, ten_nha_kho) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/get_dia_chi',
    data: { ten_khach_gui, ten_nha_kho }
  });
  return response.data;
}

export async function gopHuyLenhApi(list_chuyen_di, type) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/tool/gop_huy_lenh',
    data: { list_chuyen_di, type }
  });
  return response.data;
}

export async function getListKhoByListApi(list_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/get_list_kho_by_list',
    data: { list_khach_gui }
  });
  return response.data;
}

export async function getListPhanPhoiByListApi(list_khach_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/dia_diem/get_list_kho_pp_by_list',
    data: { list_khach_gui }
  });
  return response.data;
}

export async function getTripRoute(id_chuyen_di) {
  const response = await request.request({
    method: 'POST',
    url: '/gps_trip_action/get_route_lich_trinh',
    data: { id_chuyen_di }
  });
  return response.data;
}

export async function getCarReportApi(start_date, end_date) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/xe_theo_thoi_gian',
    data: {
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD')
    }
  });
  return response.data;
}

export async function getCarReportExcelApi(start_date, end_date) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/xe_theo_thoi_gian_excel',
    data: {
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD')
    }
  });
  return response.data;
}

export async function getEmptyTruckApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/report/empty_truck_report'
  });
  return response.data;
}
export async function getTripSuggestCategory(ten_nguoi_gui) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/chuyen_di_info/get_list_hang_hoa_info',
    data: { ten_nguoi_gui }
  });
  return response.data;
}

export async function calculateBSX(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/report/truck/calculate_km',
    data
  });
  return response.data;
}

export async function vehicleCurrentUpdateListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/current_update_list'
  });
  return response.data;
}

export async function driverCurrentUpdateListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/current_update_list'
  });
  return response.data;
}

export async function vehicleSortListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/list_sort_key'
  });
  return response.data;
}

export async function driverSortListApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_management/get_list',
    data: data
  });
  return response.data;
}

export async function salaryListApi(data) {
  console.log(data);
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_salary/list',
    data: data
  });
  return response.data;
}

export async function revenueDownloadExcelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/bao_cao/doanh_thu_excel',
    data
  });
  return response.data;
}

export async function walletDownloadExcelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/wallet/excel_download',
    data
  });
  return response.data;
}
export async function updateSalaryApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_salary/create',
    data
  });
  return response.data;
}

export async function updateSalaryListCalculateApi(list_chi_phi) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_salary/update_cong_thuc_chi_phi_khac',
    data: {
      list_chi_phi
    }
  });
  return response.data;
}

export async function salaryListCalculateApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/driver_salary/list_key_cong_thuc',
    data: {}
  });
  return response.data;
}

export async function companyPriceApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/company_cost/get_list',
    data
  });
  return response.data;
}
export async function downloadExcelPriceCompany(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/company_cost/excel_download',
    data
  });
  return response.data;
}

export function uploadPriceCompanyExcel(file, onUploadProgress) {
  try {
    let formData = new FormData();

    formData.append('uploaded_file', file);

    return request.post('/tms/company_cost/update_excel', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadPointFileApi', error);
  }
}

export async function getListNameDefault(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/get_list_name_default',
    data
  });
  return response.data;
}

export async function getCurrentExcelOrderListApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/get_current_excel_order_list',
    data
  });
  return response.data;
}
export async function saveExcelOrderApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/tms/excel/save_excel_order',
    data
  });
  return response.data;
}

export async function getListPlaceApi() {
  const response = await request.request({
    method: 'POST',
    url: '/tms/phuong_tien/list_bien_so',
    data: {}
  });
  return response.data;
}


export async function downloadExcelUser() {
  const response = await request.request({
    method: 'POST',
    url: '/user/excel_download'
  });
  return response.data;
}